import React from 'react';
import '../index.css';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useTheme } from '@emotion/react';

import { useAuth0 } from "@auth0/auth0-react";

const ButtonSignUp = ({variant}) => {
  const theme = useTheme();
  const { loginWithRedirect} = useAuth0();

  const handleLoginClick = () => {
    // Save the current page URL in localStorage before redirecting for login
    const currentPath = window.location.pathname;
    const isRoot = currentPath === "/";

    // Set `returnTo` relative path
    const returnTo = isRoot ? "/news" : currentPath;
    localStorage.setItem('redirectTo', returnTo); // Store redirect URL
    loginWithRedirect(); // Trigger Auth0 login
  };

  return (
   
          <Button
            variant={variant}
            color="primary"
            onClick={handleLoginClick}
            sx={{fontWeight:'bold',
              width:'100px',
              borderWidth: '2px'}}
          >
            Sign-in
          </Button>

  );
};

export default ButtonSignUp;