import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Routes, Route,Navigate} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header';
import Home from './pages/HomeNew';
import EarningsCalendar from './pages/EarningsCalendar';
import Privacy from './pages/Privacy'; 
import Terms from './pages/Terms'; 
import ReactGA from "react-ga4";
import { lightTheme, darkTheme } from './components/theme'; // Adjust the path as needed
import { ThemeProvider } from '@emotion/react';
import stockTickers from './data/companylist.json'; // Adjust the path as needed
import Footer from './components/Footer';
import Coverage from './pages/Coverage'
import IndustryMetrics from './pages/IndustryMetrics'
import Benchmarks from './pages/Benchmarks'
import AboutUs from './pages/AboutUs';
import MarketCoverage from './subpages/CoverageMarket';
import MetricsValuation from './subpages/MetricsValuation';
import CompSheetNew from './subpages/CoverageCompSheet';
import VC from './subpages/CoverageVC';
import PE from './subpages/CoveragePEAcquisition';
import Valuation from './subpages/BenchmarksValuation';
import Segmentation from './subpages/BenchmarksSegmentation';
import Cloud from './subpages/BenchmarksCloud';
import About from './subpages/SecurityAnalysisAbout';
import KeyMetrics from './subpages/SecurityAnalysisKeyMetrics';
import Model from './subpages/SecurityAnalysisModel';
import Private from './subpages/SecurityAnalysisPrivate';
import SecurityAnalysis from './pages/SecurityAnalysis';
import Callback from './pages/Callback';
import News from './pages/News';

function App() {
  const [themeMode, setThemeMode] = useState(() => {
    // Check the initial color scheme
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    return darkModeMediaQuery.matches ? 'dark' : 'light';
  });

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    // Add or remove the 'dark-mode' class based on the theme mode
    document.body.classList.toggle('dark', themeMode === 'dark');
    document.body.classList.toggle('light', themeMode === 'light');
    document.documentElement.classList.toggle('dark', themeMode === 'dark');
    document.documentElement.classList.toggle('light', themeMode === 'light');
    // Update the meta theme-color tag
      // Set the scrollbar colors
    const root = document.documentElement;
    if (themeMode === 'dark') {
      root.style.setProperty('--scrollbar-track-color', '#333');
      root.style.setProperty('--scrollbar-thumb-color', '#555');
    } else {
      root.style.setProperty('--scrollbar-track-color', '#ddd');
      root.style.setProperty('--scrollbar-thumb-color', '#aaa');
    }

    let metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (!metaThemeColor) {
        metaThemeColor = document.createElement('meta');
        metaThemeColor.name = 'theme-color';
        document.head.appendChild(metaThemeColor);
    }
    metaThemeColor.content = themeMode === 'dark' ? 'rgb(22,22,22)' : "#dcdcdc"; // Set your desired colors

    // Cleanup function to remove the meta tag if needed
    return () => {
        if (metaThemeColor) {
            document.head.removeChild(metaThemeColor);
        }
    };
  }, [themeMode]);


  ReactGA.initialize("G-T93EWL2RPH");
  return (
    <ThemeProvider theme={themeMode === 'dark' ? darkTheme : lightTheme}>
    <>
      <Router>
        <Header themeMode={themeMode} toggleTheme={toggleTheme} />
          <Routes>
            
            <Route path='/' element={<Home />} />
            <Route path="/callback" element={<Callback />} />
            <Route path='/earnings/calendar' element={<EarningsCalendar />} />
            <Route path='/news' element={<News />} />

            <Route path='/industrymetrics' element={<Navigate to="/industrymetrics/dashboard" />} />
            <Route path='/industrymetrics' element={<IndustryMetrics />}>
              <Route path='dashboard' element={<MarketCoverage />} />
              <Route path='valuation' element={<MetricsValuation />} />


              {/* Add more nested routes for other subpages as needed */}
            </Route>

            <Route path='/privacy' element={<Privacy />} />
        <Route path="/about" element={<AboutUs />} />
            <Route path='/termsandconditions' element={<Terms />} />
            <Route path='/benchmarks' element={<Navigate to="/benchmarks/valuation" />} />
            <Route path='/benchmarks' element={<Benchmarks />}>
              <Route path='valuation' element={<Valuation />} />
              <Route path='segmentation' element={<Segmentation />} />
              <Route path='iaas' element={<Cloud />} />
              </Route>
            <Route path='/coverage' element={<Navigate to="/coverage/all" />} />
            <Route path='/coverage' element={<Coverage />}>
              <Route path='all' element={<MarketCoverage />} />
              <Route path='compsheet' element={<CompSheetNew />} />
              <Route path='vc' element={<VC />} />
              <Route path='acquisitions' element={<PE />} />

              {/* Add more nested routes for other subpages as needed */}
            </Route>
            {stockTickers.map(ticker => (
              <Route 
                key={`navigate-${ticker.toLowerCase()}`} // Add unique key here
                path={`/company/${ticker.toLowerCase()}`} 
                element={<Navigate to={`/company/${ticker.toLowerCase()}/keymetrics`} />} 
              />
            ))}

            {stockTickers.map(ticker => (
              <Route 
                key={ticker.toLowerCase()} // You already have a key here, so this is fine
                path={`/company/${ticker.toLowerCase()}`} 
                element={<SecurityAnalysis ticker={ticker} />} 
              >
                <Route path='valuation' element={<Valuation />} />
                <Route path='compsheet' element={<CompSheetNew />} />
                <Route path='model' element={<Model />} />
                <Route path='news' element={<About />} />
                <Route path='keymetrics' element={<KeyMetrics />} />
                <Route path='private' element={<Private />} />
              </Route>
            ))}
            

          </Routes>
          <Footer themeMode={themeMode} toggleTheme={toggleTheme} />
      </Router>
      <ToastContainer />
    </>
    </ThemeProvider>
  );
}

export default App;
