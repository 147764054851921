import React from 'react';
import { Slider,Input, Box, Typography } from '@mui/material';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import { useTheme } from '@mui/material/styles';

const FilterSlider = ({ period, sliderValue, maxSliderValue, handleSliderChange,handleInputChange,handleBlur }) => {
  const theme = useTheme();
  const label = period === 'Years' ? "Years" : "Quarters"
  
  return (
    <Box
    size="small"
    sx={{
      
      height: '30px',
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      minWidth: '200px',
      marginTop: '2px',
      maxWidth: '100%',
      flex: 1,
    }}
  >
  <Box sx={{ height:'30px',backgroundColor:theme.tableHeader.highlight,width:'240px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
    border: theme.palette.mode==='light'?`1px solid ${theme.palette.grey[400]}`:`1px solid ${theme.palette.grey[700]}`, borderRadius: '4px' }}>
      <Box sx={{ maxWidth: '68px', paddingRight:'4px',textAlign: 'center', paddingBottom: '0px' }}>
        <Typography variant="body2" sx={{ paddingLeft: '8px', color: theme.palette.text.secondary, fontSize: '9px' }}>
          Display Last {sliderValue} {label}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center', flexWrap: 'wrap' }}>
        <Box sx={{ width: '175px' }}>
          <Box
            sx={{
              backgroundColor: theme.tableHeader.highlight,
              height: '28px', // Increase height to allow more space for child elements
              display: 'flex', // Use flex to align children
              justifyContent: 'space-between', // Distribute space evenly between items
              alignItems: 'center', // Vertically center items
              px: 1,
              borderLeft: `1px solid ${theme.palette.divider}`,
            }}
          >

              <ViewTimelineIcon sx={{ color: theme.palette.text.secondary, fontSize: '20px' }} />

            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <Slider
                sx={{ width: '90px' }}
                value={sliderValue}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                min={2}
                max={maxSliderValue}
              />
            </Box>

            <Input
              value={sliderValue}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 2,
                max: maxSliderValue,
                type: 'text',
                pattern: '\\d*',
                'aria-labelledby': 'input-slider',
              }}
              sx={{
                px: 1,
                color: theme.palette.text.secondary,
                height: '26px', // Match height of other elements
                fontSize: '12px',
                width: '30px',
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': { display: 'none' },
              }}
            />
          </Box>
        </Box>


  </Box>
</Box>
</Box>
  );
};

export default FilterSlider;