import React from "react";
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { alpha, Container, Typography, Stack, Box, Icon,Paper,useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import HomeSecurityAnalysis from '../subpages/HomeSecurityAnalysis';
import HomeCompSheet from '../subpages/HomeCompSheet';
import { Button } from '@mui/material';
import { useState, useEffect } from 'react';
import './Home.css';
import TypingAnimation from "../components/TypingAnimation";
import CompanyCarousel from "../components/CompanyCarousel";
import ButtonSignUp from "../components/ButtonSignup";
import ButtonSignUpBottom from "../components/ButtonSignupBottom";
import ButtonLogin from "../components/ButtonLogin"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0
const CounterDisplay = React.memo(({ count, label }) => (
  <Box className="coverage-count">
    <Typography sx={{ color: 'primary.main', fontSize: { xs: '26px', sm: '30px', md: '40px' } }} variant="h2">
      {count}+
    </Typography>
    <Typography sx={{ color: 'text.secondary', width:'200px',fontSize: { xs: '20px', sm: '20px', md: '22px' } }} variant="h2">
      {label}
    </Typography>
  </Box>
));

function Home(){
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  
  const textHide  = useMediaQuery('(max-width:1350px)'); // Adjust breakpoint as needed
  const { isAuthenticated } = useAuth0(); // Use the useAuth0 hook
  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const [companyCount, setCompanyCount] = useState(0);
  const [companyCountPrivate, setCompanyCountPrivate] = useState(0);
  const [animationsComplete, setAnimationsComplete] = useState(false);
  
  const [heightUnit, setHeightUnit] = useState('vh');
  useEffect(() => {
    const supportsSvh = CSS.supports('height', '1svh');
    if (supportsSvh) {
      setHeightUnit('svh');
    }
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);

  useEffect(() => {
    let start = null;
    const maxCount = 110;
    const increment = 10; // Increment by multiples of 5
    const speed = 60; // Adjust speed as needed

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const newCount = Math.min(maxCount, companyCount + increment * Math.floor(progress / speed));
      setCompanyCount(newCount);
      if (newCount < maxCount) {
        requestAnimationFrame(animate);
      }
    };

    if (companyCount < maxCount) {
      requestAnimationFrame(animate);
    }
  }, [companyCount]);

  useEffect(() => {
    let start = null;
    const maxCount = 160;
    const increment = 10; // Increment by multiples of 5
    const speed = 60; // Adjust speed as needed

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const newCount = Math.min(maxCount, companyCountPrivate + increment * Math.floor(progress / speed));
      setCompanyCountPrivate(newCount);
      if (newCount < maxCount) {
        requestAnimationFrame(animate);
      } else if (newCount >= maxCount) {
        setAnimationsComplete(true); // Update state when both counts are complete
      }
    };

    if (companyCountPrivate < maxCount) {
      requestAnimationFrame(animate);
    }
  }, [companyCountPrivate]);
  return (
    <Box sx={{maxWidth:'100%',}}>
    <Box sx={{height:  `calc(100${heightUnit} - 100px)`,minHeight:{ xs: '500px', sm: '550px',md:'650px'}, display: 'flex', flexDirection: 'column' }}>
    <Container
        sx={{
            maxWidth:{ xs: '100%', md:'90%',lg: '70%' },
          position: 'relative', // Add relative positioning
          display: 'flex',
          flexDirection: 'column',
          justifyContent:'center',
          alignItems: 'start',
          flex:1

        }}
      >
        {/* Purple Box with Text Content */}
        <Box >
        
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>

            <Typography
            component="h1"
            variant="h1"
            sx={{

                alignSelf: 'left',
                textAlign: 'left',
                lineHeight: { xs: 1.1, md: 1.4 },
                color: theme.palette.mode === 'dark' ? '#FFF' : 'inherit',
                fontSize: {
                    xs: '40px', // smaller font size for xs screens
                    sm:'60px',
                md: '70px', // default font size for md screens
                },
                fontWeight:400,
            }}
            >
                Research Platform 
            <Typography
                component="span"
                variant="h1"
                sx={{
                    color: theme.palette.mode === 'dark' ? '#FFF' : 'inherit',
                    
                lineHeight: { xs: 1.1, md: 1.4 },
                fontSize: {
                    xs: '40px', // smaller font size for xs screens
                    sm:'60px',
                    md: '70px', // default font size for md screens

                },
                fontWeight:300,
                }}
            >
                {" for the"}
            </Typography>

            <Typography
                component="span"
                variant="h1"
                sx={{
                color: theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                
                lineHeight: { xs: 1.1, md: 1.4 },
                fontSize: {
                    xs: '40px', // smaller font size for xs screens
                    sm:'60px',
                    md: '70px', // default font size for md screens
                },
                fontWeight:400,
                }}
            >
                {" Software Industry"}
            </Typography>
            </Typography>
            </Stack>

          </Box>
          

                

             <TypingAnimation/>
             {!isAuthenticated&&<Box sx={{display:'flex',width:'450px',maxWidth:'100%',flexWrap:'wrap',justifyContent:'flex-start',gap:'15px',marginBottom:'10px'}}>
             <ButtonSignUp variant={'outlined'}/>
             <ButtonLogin variant={'contained'}/>
             </Box>}
            </Container>


        <Paper elevation={0} sx={{backgroundColor:theme.background.default}} className="market-coverage">
        <Typography sx={{paddingBottom:'4px',color:theme.palette.text.primary,fontSize: {
                    xs: '20px',
                    sm: '26px',  
                    md: '30px', 
                }}}>
          Software Coverage Universe
        </Typography>
        
        <Box className="coverage-section">
        {/* Title */}

  {/* Count */}
  <CounterDisplay count={companyCount} label="public companies" />
  <CounterDisplay count={companyCountPrivate} label="private companies" />

  {/* Companies */}

</Box>

      </Paper>
      
          {/*  Carousel Container */}
          <Box sx={{backgroundColor:theme.background.default}}>
          <Box sx={{height:'70px',backgroundColor:theme.background.default}} className="carousel-container">
            <CompanyCarousel />

            </Box> 
            <Box display="flex" justifyContent="end" marginRight="1.5%">
            <Link 
              to="/coverage/all" 
              style={{ 
                paddingBottom: '10px',
                marginTop: '10px',
                display: 'flex', 
                alignItems: 'center', 
                textDecoration: 'none',
                transition: 'transform 0.3s ease',  // Smooth scaling transition
              }}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <Typography variant="body1" sx={{ color: theme => theme.palette.text.secondary, fontWeight: 'light' }}>
                See our full coverage
              </Typography>
              <ArrowForwardIcon sx={{ color: theme => theme.palette.text.secondary, fontSize: '16px', ml: 1 }} />
            </Link>
          </Box>

        </Box>
        </Box>
      <Box sx={{height:{md:'40px'}}}></Box>
          {/* Yellow Box with Image and Content */}
          <Box className="flex-container">
            <Paper className="yellow-box"
            id="image"
            style={{
              //outline: '1px solid',
              //outlineColor: theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
              boxShadow: theme.palette.mode === 'light' ? `0 0 8px 4px ${alpha('#9CCCFC', 0.2)}` : `0 0 12px 4px ${alpha('#033363', 0.2)}`,
              paddingBottom:'8px'
            }}
          > 
            <HomeSecurityAnalysis/>
            </Paper>

            {/*Revenue description */}
            {!textHide&&<Paper sx={{
            width:'300px',
            minWidth:'300px',
            maxWidth:'500px',
            marginRight:'8px',
              boxShadow: theme.palette.mode === 'light' ? `0 0 8px 4px ${alpha('#9CCCFC', 0.2)}` : `0 0 12px 4px ${alpha('#033363', 0.2)}`,

          }}className="revenue-description">
            <Typography sx={{marginBottom:'6px',color:theme.palette.primary, fontWeight:'bold'}}variant="body1">
              Historical Security Software Metrics
            </Typography>
            <Typography sx={{color:theme.palette.text.secondary}}variant="body1">
            Visualize trends and shifts in key software and top-line metrics with ease. Gain insights into seasonality, trends, and fundamental changes in bookings and billings. Use our intuitive UI to explore data, and for deeper analysis, seamlessly export your findings to Excel.
            </Typography>
            {false&&<div className="revenue-button">
                <Link to="/company/mdb/keymetrics">
              <Button sx={{marginTop:'6px'}}variant="outlined" color="primary" >
              See Sample Page
              </Button>
              </Link>
            </div>}

          </Paper>}
          </Box>
            


          <Box sx={{height:{md:'80px'}}}></Box>

          <Box sx={{flexWrap:'wrap-reverse',alignItems:"flex-end"}} className="flex-container">
            {/*Revenue description */}
            {!textHide&&<Paper sx={{
                    width:'200px',
                    minWidth:'200px',
                    maxWidth:'500px',
                    marginLeft:'8px',
                boxShadow: theme.palette.mode === 'light' ? `0 0 8px 4px ${alpha('#9CCCFC', 0.2)}` : `0 0 12px 4px ${alpha('#033363', 0.2)}`,

          }}className="comp-description">
            <Typography sx={{marginBottom:'6px',color:theme.palette.primary, fontWeight:'bold'}}variant="body1">
              Compare Public Companies
            </Typography>
            <Typography sx={{color:theme.palette.text.secondary}}variant="body1">
            Discover and analyze public companies with the most comprehensive software performance metrics. Effortlessly sort, filter, and interact with dynamic data, or export your customized analysis to Excel for deeper insights.</Typography>

          </Paper>}
            <Paper className="yellow-box"
            sx={{width:'1100px'}}
            id="image"
            style={{
              //outline: '1px solid',
              //outlineColor: theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
              boxShadow: theme.palette.mode === 'light' ? `0 0 8px 4px ${alpha('#9CCCFC', 0.2)}` : `0 0 12px 4px ${alpha('#033363', 0.2)}`,
              paddingBottom:'14px',
            }}
          > 
            <HomeCompSheet/>
            </Paper>

            
          </Box>
<Box sx={{height:'120px'}}></Box>
      {/* Login or Sign Up */}
      {!isAuthenticated&&<Box sx={{display:'flex',justifyContent:'center'}}>
      <Paper sx={{paddingLeft:'5%',paddingRight:'5%',boxShadow: theme.palette.mode === 'light' ? `0 0 8px 4px ${alpha('#9CCCFC', 0.2)}` : `0 0 8px 4px ${alpha('#033363', 0.2)}`,}}className="login-signup"> 

        <Typography variant="body1">
        <Box sx={{fontWeight:300,gap:'20px' ,display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
          Get started with a free account today
          <Box>
          <ButtonSignUpBottom variant={'contained'}/>
          </Box>
          </Box>
        </Typography>

      </Paper>
      </Box>}
      {!isAuthenticated&&<Box sx={{height:'80px'}}></Box>}
    </Box>
  );
}

export default Home;