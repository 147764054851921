import React, { useState, useEffect,useMemo } from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {useMediaQuery,Divider, Box, CircularProgress,Typography,Button} from '@mui/material';

import { Link } from 'react-router-dom';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import TableTrended from '../components/TableTrended';
import GraphTrended from '../components/GraphTrended';

const SecurityAnalysisKeyMetrics = ({}) => {  
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
  const textHide  = useMediaQuery('(max-width:1350px)'); // Adjust breakpoint as needed
  const theme = useTheme();
  const ticker = "MDB"
  const [loading, setLoading] = useState(true);
  const logoSource = theme.palette.mode === 'dark'?`/logos/MDB/logodark.png`:`/logos/MDB/logo.png`;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        // Ensure ticker is a string and log it for debugging
        const dynamicDataAnnual = await import(`../data/stockpage/financial/annual/${ticker}.json`);
        const dynamicDataQtr = await import(`../data/stockpage/financial/quarterly/${ticker}.json`);
        const dynamicKeyStats = await import(`../data/stockpage/keystatistics/${ticker}.json`);
        const keyStatsFilterCriteria = ['Market Cap','Enterprise Value', 'EV / Revenue', 'EV / GM','Price / FCF','Close Date']; // Example metrics to include
        // Set the data in state, assuming dynamicData.default is an array of arrays as per provided data format
        if (Array.isArray(dynamicDataAnnual.default.data)) {
          setAnnualData(dynamicDataAnnual.default.data);
          setAnnualMapping(dynamicDataAnnual.default.mappingTable);
          // Check the last revenue value and set the scale to 'B' if it's greater than $10B
          const revenueData = dynamicDataAnnual.default.data.find(item => item.field === 'Revenue');
          if (revenueData) {
            const lastRevenue = revenueData[Object.keys(revenueData).pop()]; // Get the last value
            if (lastRevenue > 2000000000) { // Check if the last revenue is greater than $10B

              setAlignmentScale('B');
            }
          }
        }
        else {
          console.error('Unexpected data format:', dynamicDataAnnual);
        } 
        if (Array.isArray(dynamicDataQtr.default.data)) {
          setQtrData(dynamicDataQtr.default.data);
          setQtrMapping(dynamicDataQtr.default.mappingTable);
        }
        else {
          console.error('Unexpected data format:', dynamicDataQtr);
        }
        if (Array.isArray(dynamicKeyStats.default[0].keyStats)) {
          setKeyStats(dynamicKeyStats.default[0].keyStats.filter(item =>
            keyStatsFilterCriteria.includes(item.field)));
        }
        else {
          console.error('Unexpected data format:', dynamicKeyStats);
        }
      } catch (error) {
        console.error(`Error fetching data for ${ticker}:, error`);
      }
     finally {
      setLoading(false); // End loading
    }
    };

    fetchData();
  }, [ticker]);
  const allColDefs = [
    { field: "Revenue",group:"Revenue Metrics"},
    { field: "Cloud ARR",group:"Revenue Metrics"},
    { field: "Non-Cloud ARR",group:"Revenue Metrics"},
    { field: "ARR",group:"Revenue Metrics"},
    { field: "Calculated Billings",group:"Revenue Metrics"},
    { field: "Current Bookings",group:"Revenue Metrics"},
    { field: "Bookings",group:"Revenue Metrics"},  
    { field: "Current RPO",group:"Revenue Metrics"},
    { field: "RPO",group:"Revenue Metrics"},
    { field: "Cloud Revenue",group:"Revenue Metrics"},
    { field: "Non-Cloud Revenue",group:"Revenue Metrics"},
    { field: "Rule of 40",group:"Business Metrics"},
    { field: "Net Retention Rate",group:"Business Metrics"},
    { field: "Current RPO Mix",group:"Business Metrics"},
    { field: "RPO Duration",group:"Business Metrics"},
    { field: "Customers",group:"Business Metrics"},
    { field: "Customers >$5k",group:"Business Metrics"},
    { field: "Customers >$10k",group:"Business Metrics"},
    { field: "Customers >$50k",group:"Business Metrics"},
    { field: "Customers >$100k",group:"Business Metrics"},
    { field: "Customers >1M",group:"Business Metrics"},
    { field: "Payback Period",group:"Business Metrics"},
    { field: "Cloud Mix",group:"Business Metrics"},
    { field: "COGs",group:"Profit"},
    { field: "Gross Margin",group:"Profit"},
    { field: "Sales & Marketing",group:"Profit"},
    { field: "SG&A",group:"Profit"},
    { field: "Research & Development",group:"Profit"},
    { field: "General & Administrative",group:"Profit"},
    { field: "Operating Income",group:"Profit"},
    { field: "Free Cash Flow",group:"Profit"},
    { field: "Net Income",group:"Profit"},
    { field: "Shares Outstanding",group:"Profit"},
  ];
  const isTablet = useMediaQuery('(max-width:1080px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  const isPhablet =useMediaQuery('(max-width:700px)');
  const [annualData, setAnnualData] = useState([]);
  const [qtrData, setQtrData] = useState([]);
  const [keyStats, setKeyStats] = useState([]);
  const [annualMapping,setAnnualMapping] = useState([]);
  const [qtrMapping,setQtrMapping] = useState([]);
  const [alignment, setAlignment] = React.useState('annual');
  const [alignmentGraph,setAlignmentGraph] = useState('quarterly')
  const [selectedTrendedMetric, setSelectedTrendedMetric] = useState('Revenue');
  const [metrics,setMetrics] = useState(['ARR','Cloud ARR','Non-Cloud ARR','Calculated Billings','Current Bookings','Bookings','Current RPO','RPO','Non-Cloud Revenue','Cloud Revenue','Revenue','Cloud Mix',


])
  const [nivoData, setNivoData] = useState([])
  const [mappingArray, setMappingArray] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterColDef, setFilteredColDef] = useState([])


    
  
  const [alignmentScale, setAlignmentScale] = React.useState('M');
  
 

  const calculateMaxSliderValue = () => {
    const mapping = alignment === 'annual' ? annualMapping : qtrMapping;
    return Math.min(mapping.length, 20);
  };
  
  const maxSliderValue = calculateMaxSliderValue();
  const initialSliderValue = Math.min(isMobile ? 2 : isTablet ? Math.min(5, maxSliderValue) : Math.min(8, maxSliderValue), maxSliderValue);
  const [sliderValue, setValue] = useState(initialSliderValue);
  

  
  useEffect(()=>{
    const slicedMapping = alignment === 'annual' 
    ? annualMapping.slice(-sliderValue) 
    : alignment === 'quarterly' 
    ? qtrMapping.slice(-sliderValue) 
    : [];

  const qtrGraphSlicedMapping = qtrMapping.slice(-4 * sliderValue);
  const filteredAnnualData = annualData.filter(item => metrics.includes(item.field));
  const filteredQtrData = qtrData.filter(item => metrics.includes(item.field));
  
  const filteredColDefAnnual = allColDefs.filter(item =>
    annualData.some(data => data.field === item.field)
  );
  
  const filteredColDefQuarter = allColDefs.filter(item =>
    qtrData.some(data => data.field === item.field)
  );

  setMappingArray(slicedMapping);
  setFilteredColDef(alignment === 'annual' ? filteredColDefAnnual: alignment === 'quarterly' ? filteredColDefQuarter : []);
  setFilteredData(alignment === 'annual' ? filteredAnnualData : alignment === 'quarterly' ? filteredQtrData : []);

  const graphFilteredData = alignmentGraph === 'annual' ? annualData : alignmentGraph === 'quarterly' ? qtrData : [];
  const graphMappingArray = alignment === 'annual' 
    ? (alignmentGraph === 'annual' ? slicedMapping : qtrGraphSlicedMapping) 
    : alignment === 'quarterly' 
    ? qtrMapping.slice(-sliderValue) 
    : [];
  const convertToNivoFormat = (field) => {
    const filtered = graphFilteredData.find(item => item.field === field);
  if (!filtered) return [];

  // Filter the data based on the periods in annualSlicedMapping and format it for Nivo
  
  const formattedData = {
    id: field,
    data: Object.keys(filtered)
      .filter(key => key !== 'field' && graphMappingArray.some(mapping => mapping.period === key))
      .map(key => ({
        x: key,
        y: filtered[key]
      }))
  };

  return [formattedData];
  };
  setNivoData(convertToNivoFormat(selectedTrendedMetric));
},[annualData,sliderValue,alignment,alignmentGraph,selectedTrendedMetric,metrics])

  
  useEffect(() => {
    const initialSliderValue = isMobile ? 2 
                          : isTablet ? Math.min(5, maxSliderValue) 
                          : Math.min(5, maxSliderValue);
    setValue(initialSliderValue);
  }, [annualData]);
  
  const valueFormatter = (params) => {
    const metricName = params.data.field; // The metric name
    const value = params.value;
    if (value === null || value === undefined) {
      return '-';  // Display 'N/A' for missing values
    }
    // Define your conditional formatting rules
    if (metricName === "Revenue" || metricName === "ARR" || metricName === "Cloud ARR" || metricName === "Non-Cloud ARR" || 
      metricName === "Cloud Revenue" || metricName === "Non-Cloud Revenue" || metricName === "Calculated Billings" || 
      metricName === "Current Bookings" || metricName === "Bookings" || metricName === "Current RPO" || metricName === "RPO" || 
      metricName === "COGs" || metricName === "Gross Margin" || metricName === "Sales & Marketing" || 
      metricName === "Research & Development" || metricName === "General & Administrative" || 
      metricName === "Operating Income" || metricName === "Net Income" || metricName === "Free Cash Flow") {
  
      const isNegative = value < 0;
      const absValue = Math.abs(value);  // Use the absolute value for formatting
      
      // Format based on alignmentScale
      if (alignmentScale === "M") {
          // Millions logic: 1 decimal place if < 100 million, otherwise no decimals
          const formattedValue = absValue < 100e6 
              ? absValue / 1e6 
              : Math.round(absValue / 1e6); // Remove decimals if >= 100M
          const decimalPlaces = absValue < 100e6 ? 1 : 0;
  
          return `${isNegative ? '-' : ''}$${formattedValue.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces })}M`;
  
      } else {
          // Billions logic: 2 decimal places if < 10 billion, 1 decimal if < 100 billion, no decimals if > 100 billion
          let formattedValue;
          let decimalPlaces;
  
          if (absValue < 10e9) {
              formattedValue = absValue / 1e9;
              decimalPlaces = 2;
          } else if (absValue < 100e9) {
              formattedValue = absValue / 1e9;
              decimalPlaces = 1;
          } else {
              formattedValue = Math.round(absValue / 1e9); // Remove decimals if >= 100B
              decimalPlaces = 0;
          }
  
          return `${isNegative ? '-' : ''}$${formattedValue.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces })}B`;
      }
  
  

    } else if (metricName ==="Rule of 40" || metricName ==="Cloud Mix"|| metricName ==="Net Retention Rate") {
      // Format as a percentage (%)
      if (value >1){
      
        return `${(value*100).toFixed(0)}%`;
      } else{
      return `${(value*100).toFixed(1)}%`;
    }
    }
    else if (metricName ==="Shares Outstanding" ) {
      // Format as a percentage (%)
      return `${(value / 1e6).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}M`
    }
    else if (metricName ==="Customers" || metricName ==="Customers >$100k"|| metricName ==="Customers >$1M") {
      // Format as a percentage (%)
      return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }
  

    // Default formatting (if no specific rule applies)
    return value.toFixed(1);
  };
  const columnDefs = useMemo(() => {
    const columns = [
      { headerName: alignment === 'annual' ? 'Fiscal Year' : 'Fiscal Quarter',
        headerComponentParams: { template: `<div class="ag-header-cell-label"> <span class="ag-header-cell-text"> <strong>${alignment === 'annual' ? 'Fiscal Year' : 'Fiscal Quarter'}</strong><br/> <span style="font-size: 0.8em;">Period Ended</span> </span> </div>`, },
        headerClass: "left-align-header",  
        cellStyle: { paddingRight: '0px' }, 
        
        pinned:'left',width:190, field: "field", sortable: false, filter: false,rowDrag:true }
    ];

    // Dynamically add fiscal year columns
    mappingArray.forEach(mapping => {
      columns.push({
        headerName: mapping.period, // Use "period" as the column header
        headerComponentParams: { template: `<div class="ag-header-cell-label"> <span class="ag-header-cell-text"> <strong>${mapping.period}</strong><br/> <span style="font-size: 0.8em;">${mapping.endDate}</span> </span> </div>`, },
        headerClass: "right-align-header",  
        field: mapping.period,          // Field matches the fiscal year (e.g., FY21, FY22)
        sortable: false,
        filter: false,
        type: "numericColumn",
        width:100,
        valueFormatter
      });
    });
    return columns;
  }, [mappingArray,alignmentScale,metrics]);
  useEffect(() => {
    // Calculate and set tableWidth whenever columnDefs changes
    const totalWidth = columnDefs.reduce((sum, colDef) => sum + colDef.width+1, 0) + 12;
    setTableWidth(totalWidth);
  }, [columnDefs]); // Add columnDefs as a dependency
  
  const [tableWidth, setTableWidth] = useState([columnDefs.reduce((sum, colDef) => sum + colDef.width+1, 0) + 12]);


  return(
    <Box sx={{ width:'100%',maxWidth:'100%',height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <Box sx={{ display: 'flex', justifyContent: 'left', paddingTop: '0px', paddingBottom: '0px', marginLeft: '1%', color: theme.palette.primary.main, fontSize: '30px', fontWeight: '600' }}>
            Security Analysis
          </Box>
          <Box sx={{paddingTop:'5px',paddingLeft:'10px',paddingRight:'10px',maxWidth:'290px' }}>
            <img 
              src= {logoSource}  
              alt={`${ticker} Icon`}
              style={{maxHeight:'45px',maxWidth:'95%'}}
            />
          </Box>

          </Box>
          {textHide&&<Box>
            <Box sx={{display:'flex',justifyContent:'center',width:'90%',marginBottom:'6px'}}>
          <Typography sx={{width:'95%',color:theme.palette.text.secondary}}variant="body1">
          Visualize trends and shifts in key software and top-line metrics with ease. Gain insights into seasonality, trends, and fundamental changes in bookings and billings. Use our intuitive UI to explore data, and for deeper analysis, seamlessly export your findings to Excel.</Typography>
          
            </Box>
            <Box sx={{marginLeft:'2%'}} className="revenue-button">
            <Link to="/company/mdb/keymetrics">
          <Button sx={{marginBottom:'6px'}}variant="outlined" color="primary" >
          See Sample Page
          </Button>
          </Link>
        </Box></Box>}
          <Box sx={{display:'flex',justifyContent:'center'}}>
          <Divider sx={{width:'95%'}}>
            
          </Divider>
          </Box>
    {loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress /> {/* This is the MUI spinner */}
      </Box>):
      (        
  <Box sx={{height:'678px',marginTop:'20px',flexGrow: 1, width:'100%'}}>
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'45%',maxHeight:'45%',width:'100%',maxWidth:'100%'}}>


      <Box  sx={{ height: '100%',maxWidth: isPhablet?'100%':'calc(100% - 230px)', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
      {nivoData && nivoData.length > 0 &&sliderValue >0 ?<GraphTrended nivoData={nivoData} selectedTrendedMetric={selectedTrendedMetric}/>:"Loading"}
      </Box>

        </Box>
        
  <Box elevation={3} sx={{ display:'flex',height: '50%', maxHeight: '50%', marginTop: '0px', display: 'flex', flexDirection: 'column' }}>
  <Box sx={{ paddingBottom:'.5%',display: 'flex', justifyContent: 'center', width: '100%',marginBottom:'8px', }}>
    <Divider sx={{   width: tableWidth -100,maxWidth:'80%' }} />
  </Box>
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', maxHeight: '100%', width: '100%' }}>
    {/* Center the table container */}
    <Box sx={{ maxWidth: isTablet ?'100%':'80%', height: '100%', maxHeight: '100%' }}>
      <TableTrended
        ticker = {ticker}
        alignmentScale={alignmentScale}
        columnDefs={columnDefs}
        filteredData={filteredData}
        tableWidth={tableWidth}
        setSelectedTrendedMetric={setSelectedTrendedMetric}
      />
    </Box>
  </Box>
</Box>

 
    </Box>)}

    </Box>

      )}
      export default SecurityAnalysisKeyMetrics;