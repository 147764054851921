import React from 'react';
import { IconButton, AppBar, Typography, useTheme, Link, Paper } from '@mui/material';
//import TwitterFollowButton from "../components/TwitterFollowButton";
//import LinkedinFollowButton from './LinkedinFollowButton';
//import Brightness4Icon from '@mui/icons-material/Brightness4';
import Box from '@mui/material/Box';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from "@mui/material";

function Footer({ toggleTheme }) {
  const theme = useTheme();
  
  const isMobile = useMediaQuery('(max-width: 720px)');
  return (
    <AppBar 
            elevation={3} 
            position="sticky" 
            sx={{ 
              paddingTop: '10px', 
              marginTop: '30px', 
              maxWidth: '100%', 
              width:'100%',
              paddingBottom: '20px',
              backgroundColor: theme.background.backgroundColor, 
              paddingLeft: '1%',
              boxShadow: theme.palette.mode === 'light'
                ? '0px -1px 1px -1px rgba(0,0,0,0.06), 0px -1px 1px 0px rgba(0,0,0,0.03), 0px -3px 3px 0px rgba(0,0,0,0.04)' // Light mode
                : '0px -1px 1px -1px rgba(0,0,0,0.4), 0px -1px 1px 0px rgba(0,0,0,0.1), 0px -3px 3px 0px rgba(0,0,0,0.2)', // Dark mode


            }}
            >

      <Typography sx={{ paddingLeft: '1%' }}>
        {/* Logo */}


        <Box 
          sx={{ 
            display: 'flex', 
            paddingLeft: isMobile?'2%':'2%', 
            marginTop:'6px',
            width: '96%', 
            justifyContent: 'flex-start', 
            alignItems: 'top', 
            flexWrap:'wrap',
          }}
        >
          
          <Box sx={{marginRight:'20vw'}}>
                  <img 
          src={theme.palette.mode === 'light'
            ? require('../data/images/logo.png')
            : require('../data/images/logodark.png')}
          alt="Logo" 
          style={{ height: '28px', marginBottom: '10px' }} 
        />
        </Box>
        <Box sx={{maxWidth:'100%',display:'flex',justifyContent:'space-evenly' }}>
          {/* Company Column */}
          <Box sx={{marginRight:'20vw'}}> 
          
            <Typography 
              variant="h6" 
              sx={{ 
                marginBottom: '5px', 
                fontWeight: 'bold', 
                textAlign: 'left', 
                textTransform: 'none', // Ensure text remains as is
                
                fontSize: '17px', 
                color: theme.palette.primary.main // Set color from theme
              }}
            >
              Company
            </Typography>
            <Box>
              <Link 
                component={RouterLink}
                to="/" 
                underline="none" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '17px', 
                  color: theme.palette.text.secondary
                }}
              >
                Homepage
              </Link>
            </Box>
            {false &&<Box>
              <Link 
                href="/about" 
                underline="none" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '17px', 
                  color: theme.palette.text.secondary
                }}
              >
                About Us
              </Link>
            </Box>}
            <Box>
              <Link 
                href="/privacy" 
                underline="none" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '17px', 
                  color: theme.palette.text.secondary
                }}
              >
                Privacy Policy
              </Link>
            </Box>
            <Box>
              <Link 
                href="/termsandconditions" 
                underline="none" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '17px', 
                  color: theme.palette.text.secondary 
                }}
              >
                Terms and Conditions
              </Link>
            </Box>
          </Box>

          {/* Platform Column */}
          <Box>
            <Typography 
              variant="h6" 
              sx={{ 
                marginBottom: '5px', 
                fontWeight: 'bold', 
                textAlign: 'left', 
                textTransform: 'none', // Ensure text remains as is
                fontSize: '17px', 
                color: theme.palette.primary.main
              }}
            >
              Platform
            </Typography>
            <Box>

              <Link 
              
              component={RouterLink}
                to="/coverage/all" 
                underline="none" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '17px', 
                  color: theme.palette.text.secondary  
                }}
              >
                Market Coverage
              </Link>
            </Box>
            <Box>
              <Link 
              component={RouterLink}
              to="/industrymetrics/dashboard" 
                underline="none" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '17px', 
                  color: theme.palette.text.secondary  
                }}
              >
                Industry Metrics
                </Link>
            </Box>
            <Box>
              <Link 
                component={RouterLink}
                to="/benchmarks/valuation" 
                underline="none" 
                sx={{ 
                  textTransform: 'none', 
                  fontSize: '17px', 
                  color: theme.palette.text.secondary  
                }}
              >
                Benchmarks
              </Link>
            </Box>
          </Box>
          
          </Box>

        </Box>

        {/* Footer End Section */}
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            marginTop: '6px', 
            paddingLeft: '2%', 
            paddingRight: '5%'
          }}
        >
          {/* Left side - Phrase */}
          <Typography 
            variant="h6" // Changed from 'body1' to 'h6' for a similar style
            sx={{ 
              textAlign: 'center', 
              textTransform: 'none', 
              fontSize: '17px',  // Set the same font size
              fontWeight:300,
              color: theme.palette.text.secondary // Ensure color matches
            }}
          >
            SoftwareIQ: Public Preview
          </Typography>

          {/* Right side - Social Media Buttons */}
          <Box display="flex" gap="20%" sx={{marginRight:'3%'}}>
            <Link href="https://twitter.com/software__iq" target="_blank" rel="noopener noreferrer" underline="none">
              <IconButton sx={{color:theme.palette.text.secondary}}>
              <XIcon sx={{ fontSize: '30px' }} />
            </IconButton>
            </Link>
            <Link href="https://www.linkedin.com/company/softwareiq" target="_blank" rel="noopener noreferrer" underline="none">
              <IconButton sx={{color:theme.palette.text.secondary}}>
              <LinkedInIcon sx={{ fontSize: '30px' }} />
            </IconButton>
            </Link>
          </Box>
        </Box>
      </Typography>

      </AppBar>

  );
}

export default Footer;