import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Box } from '@mui/material';

const Callback = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const redirectTo = localStorage.getItem('redirectTo') || '/home';  // Get the URL to redirect to
      localStorage.removeItem('redirectTo');  // Clean up after redirect
      window.location.href = redirectTo;  // Redirect back to the saved URL
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return null;
};

export default Callback;