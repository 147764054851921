import React, { useEffect, useState } from 'react';
import companyData from '../data/companylistsearch.json'; // Adjust the path as needed
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const CompanyCarousel = () => {
  const [companies, setCompanies] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    // Shuffle and duplicate companyData for a seamless loop
    const shuffledData = shuffleArray([...companyData]);
    setCompanies([...shuffledData, ...shuffledData]);
  }, []);

  const isVerySmallScreen = useMediaQuery('(max-width:725px)');
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const isMediumScreen = useMediaQuery('(max-width:1199px)');
  const isOverlapScreen = useMediaQuery('(max-width:1280px)');
  const isBigScreen = useMediaQuery('(max-width:1600px)');
  const isExtraLargeScreen = useMediaQuery('(min-width:1601px)');

  const displayCount = isVerySmallScreen ? 50 
                    : isSmallScreen ? 50 
                    : isMediumScreen ? 100
                    : isOverlapScreen ? 100  
                    : isBigScreen ? 100 
                    : isExtraLargeScreen ? 100
                    : 8;

    const baseSpeed = 150; // base speed in seconds for large screens
    const animationSpeed = isVerySmallScreen ? baseSpeed / 2
                        : isSmallScreen ? baseSpeed /2
                        : isMediumScreen ? baseSpeed 
                        : isBigScreen ? baseSpeed 
                        : baseSpeed;

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: {xs:'15px',md:'40px'},
          animation: `scroll ${animationSpeed}s linear infinite`, // Apply dynamic speed
          animationPlayState: 'running',
          '&:hover': {
            animationPlayState: 'paused', // Pauses animation on hover
          },
          '@keyframes scroll': {
            from: { transform: 'translateX(0)' },
            to: { transform: `translateX(-${displayCount * 140}px)` }, // Adjust scroll width per cycle
          },
        }}
      >
        {companies.map((company, index) => (
          <Box
            key={index}
            onClick={() => window.location.href = `company${company.link.toLowerCase()}/keymetrics`}
            sx={{
              cursor: 'pointer',
              img: {
                width: '120px',
                height: 'auto',
                maxHeight: '30px',
                borderRadius: '5px',
                objectFit: 'contain',
                transition: 'transform 0.3s ease',
                opacity: 0.65,
                '&:hover': {
                  transform: 'scale(1.1)',
                  opacity: 1,
                },
              },
            }}
          >
            <img
              src={theme.palette.mode === 'light'
                ? `/logos/${company.ticker}/logo.png`
                : `/logos/${company.ticker}/logodark.png`}
              alt={`${company.companyName} logo`}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CompanyCarousel;