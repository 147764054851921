import React, { useRef } from 'react';
import { Button, Menu, MenuItem, ButtonGroup } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@emotion/react';
import * as XLSX from 'xlsx';

const ButtonExport = ({ data,fileName }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [exportFormat, setExportFormat] = React.useState('xlsx'); // Default format
  const buttonGroupRef = useRef(null); // Create a ref for the ButtonGroup

  const handleMenuClick = () => {
    setAnchorEl(buttonGroupRef.current); // Use the ButtonGroup's ref
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelect = (format) => {
    setExportFormat(format);
    handleMenuClose();
  };

  const exportData = () => {
    if (exportFormat === 'xlsx') {
      exportToExcel();
    } else if (exportFormat === 'csv') {
      exportToCSV();
    }
  };

  const exportToCSV = () => {
    // Convert JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Convert worksheet to CSV
    const csv = XLSX.utils.sheet_to_csv(worksheet);
    // Create a blob for the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    // Create a link element to download the file
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const exportToExcel = () => {
    
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

  return (
    <>
      <ButtonGroup
        ref={buttonGroupRef}
        variant="outlined"
        size="small"
        sx={{
          marginRight:'0%',
          marginBottom:'2px',
          backgroundColor: theme.tableHeader.highlight,
          '& .MuiButton-root': {
            position: 'relative', // Ensure the button is positioned correctly
            '&:hover': {
              backgroundColor: theme.tableHeader.highlightDark, // Adjust hover color
              zIndex: 1, // Bring the button to the front on hover
            },
          },
          '& .MuiButton-root:first-of-type': {
            borderRight: '1px solid', // Ensure there is a border between buttons
          },
        }}
      >
        <Button
          sx={{
            fontSize: '12px',
            color: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            backgroundColor: theme.tableHeader.highlight,
            textTransform: 'none'
          }}
          startIcon={<DownloadIcon />}
          onClick={exportData} // Trigger export action based on selected format
        >
          {exportFormat === 'xlsx' ? 'Excel' : exportFormat.toUpperCase()}
        </Button>
        <Button
          sx={{
            color: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            backgroundColor: theme.tableHeader.highlight,
            width: '10px', // Min width for the arrow button
          }}
          onClick={handleMenuClick}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        sx={{}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem sx={{ fontSize:'14px',color: theme.palette.text.secondary,}} onClick={() => handleMenuSelect('xlsx')}>Export as Excel</MenuItem>
        <MenuItem sx={{ fontSize:'14px',color: theme.palette.text.secondary,}} onClick={() => handleMenuSelect('csv')}>Export as CSV</MenuItem>
      </Menu>
    </>
  );
};

export default ButtonExport;