import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from "@nivo/line";

const MyResponsiveLine = ({ data,theme /* see data tab */ }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const isDarkMode = theme.palette.mode === 'dark';
  
    const themeGraph = {
      textColor: isDarkMode ? 'white' : 'black',
      grid: {
        line: {
          stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
      },
      axis: {
        legend: {
          text: {
            fill: isDarkMode ? 'white' : 'black', // Set legend text color to white in dark mode
            fontWeight: 'bold',
          },
        },
        ticks: {
          text: {
            fill: isDarkMode ? 'white' : 'black',
          },
        },
      },
      markers: {
        lineColor: isDarkMode ? 'white' : 'black',
        textColor: isDarkMode ? 'white' : 'black',
      },
      tooltip: {
        container: {
          background: isDarkMode ? '#323232' : '#FFFFFF',
          color: isDarkMode ? 'white' : 'black',
        },
      },
      legends: {
        text: {
          fill: isDarkMode ? 'white' : 'black', // Set legend label color to white in dark mode
        },
      },
    };
    return(
    <ResponsiveLine
        data={data}
        theme={themeGraph}
        margin={{ top: 50, right: 20, bottom: 80, left: 60 }}
        colors={theme.palette.mode === 'dark' ? { scheme: 'dark2' } : { scheme: 'category10' }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: '0',
            max: 'auto',
            stacked: false,
            reverse: false
        }}
        yFormat=" >-.0%"
        curve="step"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: windowWidth < 900 ? -45 : data[0].data.length >15? -45:0, // Rotate labels on small screens
            legend: 'Calendar Quarter',
            legendOffset: 60,
            legendPosition: 'middle',
            tickValues: (data[0].data.length > 10 && windowWidth < 900 )
                ? data[0].data.filter((_, i) => i % Math.ceil(data[0].data.length / (windowWidth < 900 ? 5 : 10)) === 0).map(d => d.x)
                : data[0].data.map(d => d.x), // Dynamically adjust number of ticks based on screen width and data length
        }}
        axisLeft={{
            format:' >-.0%',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'YoY Growth',
            legendOffset: -50,
            legendPosition: 'middle'
        }}
        enableGridX={false}
        enableGridY={false}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaBaselineValue={0}
        enableSlices="x"
        areaOpacity={0.00}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -4,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 95,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        motionConfig="stiff"
    />
  )}
  export default MyResponsiveLine;