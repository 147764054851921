import React from 'react';
import { ListItemIcon,List, Checkbox, Box, Typography, MenuItem, Select, ListItemText } from '@mui/material';

import { useTheme } from '@mui/material/styles';

const FilterCategoryFlat = ({ checked, handleToggle, items }) => {
  const theme = useTheme();

  return (
    <Box
      size="small"
      sx={{
        height: '30px',
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        minWidth: '200px',
        marginTop: '2px',
        maxWidth: '100%',
        flex: 1,
      }}
    >
      <List size='small' disablePadding sx={{ width: '250px', display: 'flex', flexDirection: 'row' }}>
        <Select
          size="small"
          sx={{ backgroundColor: theme.tableHeader.highlight, width: '250px' }}
          multiple
          value={checked}
          onChange={handleToggle}
          renderValue={() => (
            <Typography
              variant="body2"
              sx={{
                fontSize: '12px',
                display: 'flex',
                alignContent: 'center',
                paddingTop: '3px',
                color: theme.palette.text.secondary,
              }}
            >
              Filter Cloud Provider
            </Typography>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 250,
                width: 'auto',
              },
            },
          }}
        >
          {items.map((item) => (
            <MenuItem size="small" key={item.id} value={item.id} sx={{ color:theme.palette.text.secondary,height: '40px' }}>
              <ListItemIcon size="small">
                <img src={item.icon} alt={item.label} style={{ width: '24px', height: '24px' }} /> {/* Image as icon */}
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography variant="body2">
                    {item.label}
                  </Typography>
                }
              />

              <Checkbox checked={checked.indexOf(item.id) !== -1} />
            </MenuItem>
          ))}
        </Select>
      </List>
    </Box>
  );
};

export default FilterCategoryFlat;