import React, { useState, lazy, Suspense,useEffect } from "react";
import ReactGA from 'react-ga4';
import { useNavigate,useLocation } from 'react-router-dom';
import { Collapse,IconButton,Box, Tabs,Tab,Typography,MenuItem,Menu } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0

import LoginNotice from "../components/LoginNotice";
import LockIcon from '@mui/icons-material/Lock';


const MarketCoverage = lazy(() => import("../subpages/CoverageMarket"));
const Unicorn = lazy(() => import("../subpages/CoverageVC"));
const CompSheet = lazy(() => import("../subpages/CoverageCompSheet"));
const PE = lazy(() => import("../subpages/CoveragePEAcquisition"));

function Coverage() {
  const { isAuthenticated } = useAuth0(); // Use the useAuth0 hook
  //const isAuthenticated = true
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  const isSmall  = useMediaQuery('(max-width:1300px)'); // Adjust breakpoint as needed
  const [open, setOpen] = useState(!isMobile); // State to manage the open/closed state
  const location = useLocation(); // Get current URL location
  const [anchorEl, setAnchorEl] = useState(null);
  const openBread = Boolean(anchorEl);
  const [selectedButton, setSelectedButton] = useState(0);
  const [menuLabel, setMenuLabel] = useState("Market Coverage");
  const [heightUnit, setHeightUnit] = useState('vh');

  useEffect(() => {
    const supportsSvh = CSS.supports('height', '1svh');
    if (supportsSvh) {
      setHeightUnit('svh');
    }
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);
  // Function to map URL to tab index and menu label
  const getTabFromPath = (path) => {
    switch (path) {
      case '/coverage/all':
        return { index: 0, label: 'Market Coverage' };
        case '/coverage/all/':
        return { index: 0, label: 'Market Coverage' };  
      case '/coverage/compsheet':
        return { index: 1, label: 'Public Comps' };
      case '/coverage/compsheet/':
        return { index: 1, label: 'Public Comps' };
      case '/coverage/vc':
        return { index: 2, label: 'Private Unicorns' };
      case '/coverage/vc/':
        return { index: 2, label: 'Private Unicorns' };
      case '/coverage/acquisitions':
        return { index: 3, label: 'Acquisitions' };
      case '/coverage/acquisitions/':
        return { index: 3, label: 'Acquisitions' };
      default:
        return { index: 0, label: 'Market Coverage' };
    }
  };
  // Set tab based on current URL on load
  useEffect(() => {
    const { index, label } = getTabFromPath(location.pathname);
    setSelectedButton(index);
    setMenuLabel(label);
  }, [location.pathname]);

  const handleToggleCollapse = () => {
    setOpen(!open);
  };
  // Function to handle category filter change

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};
const handleButtonClick = (index) => {
  setSelectedButton(index);
};
const handleMenuItemClick = (index, label, link) => {
  setSelectedButton(index);
  setMenuLabel(label);
  handleClose();
  navigate(link);
};
  // Filter the data based on the selected category

  return (
    <Box 
      sx={{ 
        height:  `calc(100${heightUnit} - 100px)`,
        marginBottom: '0px', 
        display: 'flex', 
        flexDirection: 'column', 
        overflowY: 'auto', 
        flexGrow: 1 // Ensure this container can grow to fill available space
      }}
    >

  <Box
    sx={{
      backgroundColor: theme.background.backgroundColor,
      marginBottom: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      boxShadow: open ? '0px':theme.palette.mode === 'light'
    ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
    : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
    
    }}
    elevation={0} // No default elevation, custom shadow
  >
  {(isMobile||!open) &&
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>


    <Typography
        variant="body2"
        sx={{ color: theme.palette.primary.main, fontWeight:'normal',marginRight: '.5%', cursor: 'pointer' }}
      >
        Market Coverage
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.primary.main, fontWeight:'normal',marginRight: '.5%', cursor: 'pointer' }}
      >
        /
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',  // Make sure the entire box is clickable
          marginRight: '5%', 
        }}
        onClick={handleClick}
      >
        <Typography
          variant="body2"
          sx={{ 
            color: theme.palette.primary.main, 
            fontWeight: 'normal', 
            
          }}
        >
          {menuLabel}
        </Typography>
        <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
      </Box>
      
      <Menu
      anchorEl={anchorEl}
      open={openBread}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleMenuItemClick(0, 'Market Coverage', '/coverage/all')}>Market Coverage</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick(1, 'Public Comps', '/coverage/compsheet')}><Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Public Comps
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box></MenuItem>
      <MenuItem onClick={() => handleMenuItemClick(2, 'Private Unicorns', '/coverage/vc')}><Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Private Unicorns
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box></MenuItem>
      <MenuItem onClick={() => handleMenuItemClick(3, 'Acquisitions', '/coverage/acquisitions')}><Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Acquisitions
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box></MenuItem>
    </Menu>
    
    {!open&& <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              borderRadius:'5%',
              padding: "0px 2px",
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            <Typography variant="body2" sx={{color: theme.palette.text.secondary,fontStyle:'italic'}}>{(isMobile )?'Filters':'Expand Menu'}</Typography>
            {<ExpandMoreIcon />}
          </IconButton>}
          </Box>}
  {!isMobile&&<Collapse in={open} sx={{ width: '100%' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '4px', paddingBottom: '0px', marginLeft: '0%', color: theme.palette.primary.main, fontSize: '30px', fontWeight: '600' }}>
      Market Coverage 
    </Box>
    <Box sx={{paddingTop:'2px',paddingLeft:'1%',paddingRight:'1%',maxWidth:'100%',display:'flex', justifyContent:'center'}}>
      <Box sx={{width:'900px',display:'flex', justifyContent:'center',borderBottom: `1px solid ${theme.palette.divider}`}}>
    <Tabs 
      scrollButtons={"auto"}
      variant={"scrollable"}
      allowScrollButtonsMobile 
      value={selectedButton} // Bind the Tabs component to the selected tab
      onChange={(e, newValue) => setSelectedButton(newValue)} // Update selectedButton when tab changes
        
        elevation={0} sx={{height:'30px',minHeight:'30px',borderBottom: `0px solid ${theme.palette.divider}`,maxWidth:'100%',marginLeft:'0%', backgroundColor: 'transparent' ,display:'flex', justifyContent:'center',
        '& .MuiTabs-scrollButtons': {
        color: theme.palette.text.secondary, // Set the scroll buttons color to match the theme
        '&:hover': {
          backgroundColor: theme.palette.action.hover, // Optional: add hover effect
        },
        '&.Mui-disabled': {
          opacity: 0.3, // Change disabled state opacity if needed
        }
      },}}>
        {true&&(<Tab
        label="Market Coverage"
        
        style={{ textTransform: 'none' }}
          sx={{
            fontSize: '13px',
            padding: '0px 12px',
            height:'28px',
            minHeight:'28px'
          }}
          onClick={() => {
            setSelectedButton(0);
            navigate('/coverage/all'); // Replace with your link
        }}
        >
        </Tab>)}
        {true&&(<Tab
        label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            Public Comps
            {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
          </Box>
        }
        
        style={{ textTransform: 'none' }}
          sx={{
            fontSize: '13px',
            
            padding: '0px 12px',
            height:'28px',
            minHeight:'28px'
          }}
          onClick={() => {
            handleButtonClick(1);
            navigate('/coverage/compsheet'); // Replace with your link
          }}
        >
        </Tab>)}
        {true&&(
        <Tab
        label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            Private Unicorns
            {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
          </Box>
        }
        
        style={{ textTransform: 'none' }}
          sx={{
            fontSize: '13px',
            
            padding: '0px 12px',
            height:'28px',
            minHeight:'28px'
          }}
          onClick={() => {
            setSelectedButton(2);
            navigate('/coverage/vc'); // Replace with your link
        }}
          
        >
        </Tab>)}
        {true&&(
        <Tab
        label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            Acquisitions
            {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
          </Box>
        }
        
        style={{ textTransform: 'none' }}
          sx={{
            fontSize: '13px',
            
            padding: '0px 12px',
            height:'28px',
            minHeight:'28px'
          }}
          onClick={() => {handleButtonClick(3)
            navigate('/coverage/acquisitions'); // Replace with your link
          }}
        >
        </Tab>)}
      </Tabs>
      </Box>
      </Box>
      </Collapse>}
      
    </Box>
    <Suspense >
        {selectedButton === 0 && <MarketCoverage open={open} handleToggleCollapse={handleToggleCollapse} />}
        {selectedButton === 1 && isAuthenticated && <CompSheet open={open} handleToggleCollapse={handleToggleCollapse} />}
        {selectedButton === 2 && isAuthenticated && <Unicorn open={open} handleToggleCollapse={handleToggleCollapse} />}
        {selectedButton === 3 && isAuthenticated && <PE open={open} handleToggleCollapse={handleToggleCollapse} />}
        {(selectedButton !== 0) &&  !isAuthenticated && <LoginNotice />}
      </Suspense>
</Box>
  );
}

export default Coverage;