import React from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import { Box } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';

import MyResponsiveLine from './KeyBusinessMetricsChart'



const SecurityAnalysisKeyMetrics = ({nivoData,selectedTrendedMetric}) => {  

  const theme = useTheme();
  const graphWidth= () =>{
    // Calculate and set tableWidth whenever columnDefs changes
    const graphLength = nivoData?.[0]?.data?.length || 0;
    if (graphLength <5)
    {

      return 700
    }
    else if( graphLength <10){
      return 700
    }
    else if( graphLength <15){
      return 750
    }
    else if( graphLength <20){
      return 800
    }
    else if( graphLength <30){
      return 900
    }
    else if( graphLength <40){
      return 1100
    }
    else if( graphLength <50){
      return 1300
    }
    else if( graphLength <60){
      return 1500
    }
    else{
      return 1500
    }


  }
  
  
  return(
    
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ 
        flex: 1, display: 'flex', 
        justifyContent:'space-between',
        paddingBottom: '6px', 
        width: graphWidth, 
        maxWidth: "98%", 
        color: theme.palette.primary.main, 
        fontSize: '24px', 
        fontWeight: '300 ' , // Use !important to enforce the style
        textAlign: 'left', // Ensure text is left-aligned
        paddingLeft: '.5%',
    }}
    style={{ fontWeight: '300' }} // Inline style for testing
    >

      {selectedTrendedMetric} 
  
    </Box>
    <Box sx={{ display: 'flex', height: '100%', maxWidth:'95%',width: graphWidth }}>
    <div
      style={{
        position: 'relative',
        flex: 1, // This allows it to grow and fill the container
      }}
    >
      <div style={{
        position: 'absolute', width: '100%', height: '98%',
      }}>
          <MyResponsiveLine data={nivoData} theme={theme} />
          <img
                src={require('../data/images/logo.png')}
                alt="Website Logo"
                style={{
                  position: 'absolute',
                  bottom: 45, // Adjust the distance from the bottom
                  right:  52, // Adjust the distance from the right
                  width: '150px', // Set the desired width for the postage stamp
                  height: 'auto', // Maintain aspect ratio
                  opacity: 0.2, // Set the desired opacity for the watermark
                }}
              />
              </div>
              </div>
              </Box>
      </Box>
      )}
      export default SecurityAnalysisKeyMetrics;