import React from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import { Box, useTheme } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { format, differenceInMonths } from 'date-fns';
import { ResponsiveLine } from "@nivo/line";

// Custom hook to get window size
const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const MyResponsiveLine = ({ data }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const windowSize = useWindowSize();

  // Get the number of months in the data set
  const dataStart = new Date(data[0].data[0].x);
  const dataEnd = new Date(data[0].data[data[0].data.length - 1].x);
  const numberOfMonths = differenceInMonths(dataEnd, dataStart);

  // Dynamically adjust tick values based on window size and dataset length
  const getTickValues = () => {
    if (numberOfMonths <= 12 && windowSize.width > 900) {
      return 'every 1 month'; // Show all months if the data is small
    }
    else if (numberOfMonths >= 24 && windowSize.width > 900) {
        return 'every 2 month'; // Show all months if the data is small
      }
    else if (windowSize.width < 900 && numberOfMonths<=24) {
      return 'every 2 months'; // Fewer labels for small screens
    } else if (windowSize.width < 900 && numberOfMonths<=36) {
      return 'every 3 months'; // Moderate number of labels for medium screens
    } else {
      return 'every 6 months'; // More labels for larger screens
    }
  };

  const formatValue = (value) => {
    const absValue = Math.abs(value);
    if (absValue >= 1e11) return `${(value / 1e9).toFixed(0)}B`;
    if (absValue >= 1e9) return `${(value / 1e9).toFixed(1)}B`;
    if (absValue >= 1e7) return `${(value / 1e6).toFixed(0)}M`;
    if (absValue >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
    if (absValue >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
    if (absValue >= 3) return value.toFixed(2);
    if (absValue === 0) return value.toFixed(0);
    return absValue >= 1 ? `${(value * 100).toFixed(0)}%` : `${(value * 100).toFixed(1)}%`;
  };

  const themeGraph = {
    textColor: isDarkMode ? 'white' : 'black',
    grid: {
      line: {
        stroke: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      },
    },
    axis: {
      legend: {
        text: {
          fill: isDarkMode ? 'white' : 'black',
          fontWeight: 'bold',
        },
      },
      ticks: {
        text: {
          fill: isDarkMode ? 'white' : 'black',
        },
      },
    },
    markers: {
      lineColor: isDarkMode ? 'white' : 'black',
      textColor: isDarkMode ? 'white' : 'black',
    },
    tooltip: {
      container: {
        background: isDarkMode ? '#323232' : '#FFFFFF',
        color: isDarkMode ? 'white' : 'black',
      },
    },
    legends: {
      text: {
        fill: isDarkMode ? 'white' : 'black',
      },
    },
  };

  return (
    <ResponsiveLine
      data={data}
      theme={themeGraph}
      margin={{ top: 38, right: 50, bottom: 50, left: 75 }}
      colors={isDarkMode ? { scheme: 'dark2' } : { scheme: 'category10' }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
        precision: 'day',
      }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat={(value) => formatValue(value)}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        format: '%b-%y',
        tickValues: getTickValues(),
        legendOffset: -12,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'ARR',
        legendOffset: -60,
        legendPosition: 'middle',
        format: (value) => formatValue(value),
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      curve="cardinal"
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableGridX={false}
      enableGridY={true}
      enablePoints={true}
      enableArea={true}
      enableSlices="x"
      areaOpacity={0.05}
      legends={[
        {
          anchor: 'top-right',
          direction: 'column',
          translateX: -80,
          translateY: -40,
          itemsSpacing: 0,
          itemWidth: 95,
          itemHeight: 20,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
        },
      ]}
      sliceTooltip={({ slice }) => (
        <Box
          sx={{
            background: isDarkMode ? '#323232' : '#FFFFFF',
            color: isDarkMode ? 'white' : 'black',
            padding: '0px',
            borderRadius: '3px',
            boxShadow: isDarkMode
              ? '0 1px 2px rgba(255, 255, 255, 0.15)'
              : '0 1px 2px rgba(0, 0, 0, 0.15)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.tableHeader.color,
              padding: '4px',
              fontSize: '12px',
              marginBottom: '5px',
              fontWeight: 'bold',
            }}
          >
            {format(new Date(slice.points[0].data.x), 'MM/dd/yy')}
          </div>
          {slice.points.map((point) => (
            <Box
              key={point.id}
              sx={{ padding: '8px', display: 'flex', alignItems: 'center' }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: point.serieColor,
                  borderRadius: '50%',
                  marginRight: '8px',
                }}
              />
              <div style={{ fontSize: '12px' }}>
                <strong>{point.serieId}</strong>: {formatValue(point.data.y)}
              </div>
            </Box>
          ))}
        </Box>
      )}
    />
  );
};

export default MyResponsiveLine;
