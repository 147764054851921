
import React, { useEffect,  useRef, useMemo } from "react";
import { format, isToday, isYesterday } from 'date-fns';
import {toZonedTime  } from 'date-fns-tz';
import { Typography,Box, Paper, Link, CircularProgress } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { Link as RouterLink } from 'react-router-dom';

const TableNews = ({newsData,theme}) => {  
    
    const getDateSuffix = (day) => {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
          case 1: return "st";
          case 2: return "nd";
          case 3: return "rd";
          default: return "th";
        }
      };
    
    const formattedDate = () => {
        const today = new Date();
        const day = today.getDate();
        const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(today);
        const year = today.getFullYear();
        const suffix = getDateSuffix(day);
        return `${month} ${day}${suffix}, ${year}`;
      };
      
    const currentDate =(formattedDate());
    const gridRef = useRef(null); // Reference for the AG Grid
    const linkRenderer = (params) => {

        const url = params.data.link; // Access the link from params.data
        const ticker = params.data?.ticker;
        const hasTicker = ticker !== "";
        const source = params.data.sourceName;
        const sourceIcon = source.replace(/\s+/g, "_");
    
        const iconSrc = hasTicker? theme.palette.mode === 'dark'
          ? `/logos/${ticker}/icondark.png`
          : `/logos/${ticker}/icon.png`:
          theme.palette.mode === 'dark'?
          `/logos/news/${sourceIcon}dark.png`
          :`/logos/news/${sourceIcon}.png`;
    
    
        if (      params.data.publishDate === null ||       params.data.publishDate === undefined) {
          return "-";
        }
    
          // Ensure the date is parsed in UTC, handling any format inconsistencies
      let utcDate;
      if (params.data.publishDate) {
        // Ensure the string is in ISO format (add `Z` if missing)
        const dateString = params.data.publishDate.includes('Z') 
          ? params.data.publishDate 
          : `${params.data.publishDate}Z`; // Append Z to ensure UTC
        utcDate = new Date(dateString);  // This will now be parsed as UTC
      }
    
    
      // Get the user's local timezone (e.g., "America/Los_Angeles")
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    
      // Convert UTC date to the user's local time zone
      const localDate = toZonedTime(utcDate, userTimeZone);
    
      // Now, format the date based on the local time zone
      let formattedDate;
    
      // Check if the date is today or yesterday
      if (isToday(localDate)) {
        formattedDate = `Today, ${format(localDate, 'h:mm a')}`;
      } else if (isYesterday(localDate)) {
        formattedDate = `Yesterday, ${format(localDate, 'h:mm a')}`;
      } else {
        formattedDate = format(localDate, 'MM/dd/yyyy, h:mm a');
      }
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link
            component={RouterLink}
            to={url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} // Inherit color and remove underline
          >
            {(
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '25px', minWidth: '25px', paddingRight: '2.5vw' }}>
                <img
                  src={iconSrc}
                  alt={`${ticker} logo`}
                  style={{ width: 15, height: 15, objectFit: 'contain' }}
                  onError={(e) => {
                    e.target.style.display = "none"; // Hide the image if not found
                  }}
                />
              </Box>
            )}
            <Typography
              component="span"
              sx={{
                
                textWrap:'wrap',
                whiteSpace: 'normal', // add this to ensure wrapping
                ml: 0.0, // Small margin for spacing
                fontSize:'14px',
                paddingTop: '4px',
                paddingBottom:'4px', // Remove default padding
                margin: 0,  // Remove default margin
              }}
            >
            {params.data.title}
            <Typography
              component="span"
              sx={{
                color: theme.palette.text.secondary,
                fontStyle: 'italic', // Italic style for readability
                fontSize:'12px',
                ml: 0.0, // Small margin for spacing
                padding: 0, // Remove default padding
                margin: 0,  // Remove default margin
              }}
            >
              {": "}
              {source}
              {" - "}
              {formattedDate}
            </Typography>
            </Typography>
    
    
    
          </Link>
        </Box>
        );
      };
      const allColDefs = [
    
        { field: "title",
          headerName: `Software News - ${currentDate}`, sortable: false, filter: false,cellRenderer:linkRenderer, autoHeight:true,resizable:false,floatingFilter: false, maxWidth: 2000,width: 2000,
          suppressMovable: true },  
    
      
     ];


       const tableWidth = Math.max(
         allColDefs.reduce((sum, colDef) => sum + colDef.width, 0) + 10,
         500
       );
       
       const defaultColDef = useMemo(() => ({
         filter: true,
         floatingFilter: false,
         sortable: true,
         resizable: true,
         suppressMenuHide: false,
         suppressHeaderMenuButton: false,
       }), []);
     const onGridReady = (params) => {
         // This ensures that grid API is available before calling sizeColumnsToFit
         if (params.api) {
           params.api.sizeColumnsToFit(); // Resize columns to fit
         }
       };
       useEffect(() => {
         if (gridRef.current && gridRef.current.api) {
           gridRef.current.api.sizeColumnsToFit(); // Adjust columns on theme change
         }
       }, [theme.palette.mode]);
       useEffect(() => {
         const onResize = () => {
           if (gridRef.current && gridRef.current.api) {
             gridRef.current.api.sizeColumnsToFit(); // Resize columns to fit on window resize
           }
         };
     
         window.addEventListener("resize", onResize);
     
         return () => {
           window.removeEventListener("resize", onResize);
         };
       }, []);
       const tableHeight = newsData.length *30 + 62

  return(
    

    <Box 
    sx={{ 
      flex: 1, 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      height: '100%', // Ensure the parent container's height is defined
      width: '100%' // Take up full width of the parent
    }}
  >
  <Box sx={{ 
      display: 'flex', 
      justifyContent:'space-between',
      alignItems:'center',
      paddingBottom: '8px', 
      width: '100%', 
      maxWidth: "100%", 
      color: theme.palette.primary.main, 
      fontSize: '2px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >

    

  </Box>

  <Paper
    className={theme.palette.mode === 'dark' ? "ag-theme-material-dark" : "ag-theme-material"}
    style={{
      maxWidth: "98%", // Ensure Paper fills the available space
      width: '98%', // Ensure Paper fills the available space
      height:'100%',
      maxHeight: tableHeight, // Ensure this is 100% of the parent container's height
    "--ag-odd-row-background-color": theme.tableHeader.highlight,
    "--ag-row-hover-color": theme.tableHeader.highlightDark,
    "--ag-material-primary-color": theme.palette.primary.main,
    "--ag-material-accent-color": theme.palette.primary.main,    
    "--ag-header-cell-color": "theme.palette.text.secondary", // For text color
    "--ag-header-height": "50px", // Optional: Adjusts the header height


    }}
    sx={{
      '& .ag-header-cell': {
        backgroundColor: theme.table.header,
        color: theme.palette.text.secondary,
        letterSpacing:"normal",
        wordSpacing:"normal",
        whiteSpace: "normal", // Allow text wrapping
        lineHeight: "1.2em", // Adjust line height for better readability
        textAlign: 'center', // Optional: Center text if needed
        padding: '0px 20px', // Adjust padding as needed (top/bottom left/right)

      },
      '& .ag-header-cell-text': {
        letterSpacing:"normal",
        textTransform:"none",
        wordSpacing:"normal",
        whiteSpace: "normal", // Allow text wrapping
      },
    }}
  >

    
    <AgGridReact
      ref={gridRef}
      rowData={newsData}
      columnDefs={allColDefs}
      defaultColDef={defaultColDef}
      headerHeight={30}
      rowHeight={30}
      onGridReady={onGridReady} 
    />
    {/* Loading Spinner */}

  </Paper>
 
</Box>

      )}
      export default TableNews;