import React from "react";
import '../index.css'

import { Box } from '@mui/material';
function Privacy(){



  return(
    <>
    <Box sx={{paddingTop:'30px',paddingLeft:'4%',paddingRight:'4%',backgroundColor:'white'}} >
    <div><strong><span data-custom-class="title">PRIVACY POLICY</span></strong></div>
<div>&nbsp;</div>
<div><strong><span data-custom-class="subtitle">Last updated&nbsp;May 26, 2024</span></strong></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">This privacy notice for&nbsp;SoftwareIQ, LLC&nbsp;(doing business as&nbsp;SoftwareIQ)&nbsp;("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"</span><span data-custom-class="body_text">), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:</span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Visit our website&nbsp;at&nbsp;<a href="https://softwareiq.io/" target="_blank" data-custom-class="link">https://softwareiq.io</a><span data-custom-class="body_text">, or any website of ours that links to this privacy notice</span></span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Engage with us in other related ways, including any sales, marketing, or events</span></li>
</ul>
<div><span data-custom-class="body_text"><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.&nbsp;If you still have any questions or concerns, please contact us at&nbsp;software.iq@outlook.com.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><strong><span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our&nbsp;</em></strong></span><span data-custom-class="body_text"><strong><em>table of contents</em></strong></span><span data-custom-class="body_text"><strong><em>&nbsp;below to find the section you are looking for.</em></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>What personal information do we process?</strong>&nbsp;When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about&nbsp;</span><span data-custom-class="body_text">personal information you disclose to us</span><span data-custom-class="body_text">.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>Do we process any sensitive personal information?</strong>&nbsp;We do not process sensitive personal information.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>Do we collect any information from third parties?</strong>&nbsp;We do not collect any information from third parties.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>How do we process your information?</strong>&nbsp;We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about&nbsp;</span><span data-custom-class="body_text">how we process your information</span><span data-custom-class="body_text">.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>In what situations and with which&nbsp;parties do we share personal information?</strong>&nbsp;We may share information in specific situations and with specific&nbsp;third parties. Learn more about&nbsp;</span><span data-custom-class="body_text">when and with whom we share your personal information</span><span data-custom-class="body_text">.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>How do we keep your information safe?</strong>&nbsp;We have&nbsp;organizational&nbsp;and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other&nbsp;unauthorized&nbsp;third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about&nbsp;</span><span data-custom-class="body_text">how we keep your information safe</span><span data-custom-class="body_text">.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>What are your rights?</strong>&nbsp;Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about&nbsp;</span><span data-custom-class="body_text">your privacy rights</span><span data-custom-class="body_text">.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>How do you exercise your rights?</strong>&nbsp;The easiest way to exercise your rights is by&nbsp;visiting&nbsp;<a href="https://www.softwareiq.io/contactus" target="_blank" data-custom-class="link">https://www.softwareiq.io/contactus</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Want to learn more about what we do with any information we collect?&nbsp;</span><span data-custom-class="body_text">Review the privacy notice in full</span><span data-custom-class="body_text">.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div id="toc"><strong><span data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></div>
<div>&nbsp;</div>
<div>1. WHAT INFORMATION DO WE COLLECT?</div>
<div>2. HOW DO WE PROCESS YOUR INFORMATION?</div>
<div>3.&nbsp;WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</div>
<div>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</div>
<div>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</div>
<div>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?&nbsp;&nbsp;</div>
<div>7. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
<div>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
<div>9. DO WE COLLECT INFORMATION FROM MINORS?</div>
<div>10. WHAT ARE YOUR PRIVACY RIGHTS?</div>
<div>11. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
<div>12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</div>
<div>13. DO WE MAKE UPDATES TO THIS NOTICE?</div>
<div>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
<div>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div id="infocollect"><span id="control"><strong><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong></span></div>
<div>&nbsp;</div>
<div id="personalinfo"><span data-custom-class="heading_2"><strong>Personal information you disclose to us</strong></span></div>
<div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span><span data-custom-class="body_text"><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We collect personal information that you provide to us.</em></span></span></div>
</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you&nbsp;register on the Services,&nbsp;</span><span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>Personal Information Provided by You.</strong>&nbsp;The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">names</span></span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">email addresses</span></span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">usernames</span></span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">passwords</span></span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">job titles</span></span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">debit/credit card numbers</span></span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><span data-custom-class="body_text">billing addresses</span></span></li>
</ul>
<div>&nbsp;</div>
<div id="sensitiveinfo"><span data-custom-class="body_text"><strong>Sensitive Information.</strong>&nbsp;We do not process sensitive information.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>Social Media Login Data.&nbsp;</strong>We may provide you with the option to register with us using your existing social media account details, like your Facebook, X, or other social media account. If you choose to register in this way, we will collect certain profile information about you from the social media provider, as described in the section called&nbsp;"<span data-custom-class="body_text">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>"&nbsp;below.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="heading_2"><strong>Information automatically collected</strong></span></div>
<div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span><span data-custom-class="body_text"><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</em></span></span></div>
</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar technologies.&nbsp;You can find out more about this in our Cookie Notice:&nbsp;<a href="https://www.softwareiq.io/cookies" target="_blank" data-custom-class="link">https://www.softwareiq.io/cookies</a>.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">The information we collect includes:</span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><em>Log and Usage Data.</em>&nbsp;Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services&nbsp;(such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called&nbsp;"crash dumps"), and hardware settings).</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><em>Device Data.</em>&nbsp;We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><em>Location Data.</em>&nbsp;We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</span></li>
</ul>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div id="infouse"><span id="control"><strong><span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></span></div>
<div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></span></span></div>
</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.</span></li>
</ul>
<div>
<div>
<div>
<div>&nbsp;</div>
<ul>
<li><span data-custom-class="body_text"><strong>To respond to user inquiries/offer support to users.&nbsp;</strong>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To send administrative information to you.&nbsp;</strong>We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To&nbsp;fulfill&nbsp;and manage your orders.</strong>&nbsp;We may process your information to&nbsp;fulfill&nbsp;and manage your orders, payments, returns, and exchanges made through the Services.</span></li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To request feedback.&nbsp;</strong>We may process your information when necessary to request feedback and to contact you about your use of our Services.</span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To send you marketing and promotional communications.&nbsp;</strong>We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see&nbsp;"</span><span data-custom-class="body_text">WHAT ARE YOUR PRIVACY RIGHTS?</span><span data-custom-class="body_text">"&nbsp;below.</span></li>
</ul>
<div>
<div>
<div>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To protect our Services.</strong>&nbsp;We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</span></li>
</ul>
<div>
<div>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To identify usage trends.</strong>&nbsp;We may process information about how you use our Services to better understand how they are being used so we can improve them.</span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To determine the effectiveness of our marketing and promotional campaigns.</strong>&nbsp;We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>To save or protect an individual's vital interest.</strong>&nbsp;We may process your information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm.</span></li>
</ul>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div id="legalbases"><strong><span data-custom-class="heading_1">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></strong></div>
<div>&nbsp;</div>
<div><em><span data-custom-class="body_text"><strong>In Short:&nbsp;</strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e.,&nbsp;legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or&nbsp;fulfill&nbsp;our contractual obligations, to protect your rights, or to&nbsp;fulfill&nbsp;our legitimate business interests.</span></em></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><em><span data-custom-class="body_text"><strong><u>If you are located in the EU or UK, this section applies to you.</u></strong></span></em></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>Consent.&nbsp;</strong>We may process your information if you have given us permission (i.e.,&nbsp;consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. &nbsp;</span><span data-custom-class="body_text"></span><span data-custom-class="body_text"></span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>Performance of a Contract.</strong>&nbsp;We may process your personal information when we believe it is necessary to&nbsp;fulfill&nbsp;our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>Legitimate Interests.</strong>&nbsp;We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Send users information about special offers and discounts on our products and services</span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Analyze&nbsp;how our Services are used so we can improve them to engage and retain users</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Support our marketing activities</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Diagnose problems and/or prevent fraudulent activities</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Understand how our users use our products and services so we can improve user experience</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>Legal Obligations.</strong>&nbsp;We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.<br /></span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>Vital Interests.</strong>&nbsp;We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</span></li>
</ul>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><u><em>If you are located in Canada, this section applies to you.</em></u></strong></span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We may process your information if you have given us specific permission (i.e.,&nbsp;express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e.,&nbsp;implied consent). You can&nbsp;</span><a href="https://app.termly.io/dashboard/website/80cddfea-01f6-4342-91c2-cc04e2c8be72/privacy-policy#withdrawconsent" data-custom-class="link"><span data-custom-class="body_text">withdraw your consent</span></a><span data-custom-class="body_text">&nbsp;at any time.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">For investigations and fraud detection and prevention</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">For business transactions provided certain conditions are met</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">For identifying injured, ill, or deceased persons and communicating with next of kin</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">If the collection is solely for journalistic, artistic, or literary purposes</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">If the information is publicly available and is specified by the regulations</span></li>
</ul>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div id="whoshare"><span id="control"><strong><span data-custom-class="heading_1">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may share information in specific situations described in this section and/or with the following&nbsp;third parties.</em></span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We&nbsp;may need to share your personal information in the following situations:</span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text"><strong>Business Transfers.</strong>&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></li>
</ul>
<div>
<div>
<div>
<div>
<div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div id="cookies"><span id="control"><strong><span data-custom-class="heading_1">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services&nbsp;and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">To the extent these online tracking technologies are deemed to be a&nbsp;"sale"/"sharing"&nbsp;(which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section&nbsp;"</span><span data-custom-class="body_text">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span><span data-custom-class="body_text">"</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice:&nbsp;<span data-custom-class="body_text"><a href="https://www.softwareiq.io/cookies" target="_blank" data-custom-class="link">https://www.softwareiq.io/cookies</a></span>.</span></div>
<div>&nbsp;</div>
<div><strong><span data-custom-class="heading_2">Google Analytics</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We may share your information with Google Analytics to track and&nbsp;analyze&nbsp;the use of the Services.&nbsp;To opt out of being tracked by Google Analytics across the Services, visit&nbsp;<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" data-fr-linked="true">https://tools.google.com/dlpage/gaoptout</a>.&nbsp;For more information on the privacy practices of Google, please visit the&nbsp;<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" data-custom-class="link">Google Privacy &amp; Terms page</a>.</span></div>
<div>&nbsp;</div>
<div id="sociallogins"><span id="control"><strong><span data-custom-class="heading_1">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</em></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</span></div>
<div>&nbsp;</div>
<div id="inforetain"><span id="control"><strong><span data-custom-class="heading_1">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We keep your information for as long as necessary to&nbsp;fulfill&nbsp;the purposes outlined in this privacy notice unless otherwise required by law.</em></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).&nbsp;No purpose in this notice will require us keeping your personal information for longer than&nbsp;the period of time in which users have an account with us.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or&nbsp;anonymize&nbsp;such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></div>
<div>&nbsp;</div>
<div id="infosafe"><span id="control"><strong><span data-custom-class="heading_1">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We aim to protect your personal information through a system of&nbsp;organizational&nbsp;and technical security measures.</em></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We have implemented appropriate and reasonable technical and&nbsp;organizational&nbsp;security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other&nbsp;unauthorized&nbsp;third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span></div>
<div>&nbsp;</div>
<div id="infominors"><span id="control"><strong><span data-custom-class="heading_1">9. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market to&nbsp;children under 18 years of age.</em></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at&nbsp;<span data-custom-class="body_text">software.iq@outlook.com</span>.</span></div>
<div>&nbsp;</div>
<div id="privacyrights"><span id="control"><strong><span data-custom-class="heading_1">10. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;Depending on your state of residence in the US or in&nbsp;some regions, such as&nbsp;the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information.&nbsp;You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</em></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">In some regions (like&nbsp;the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section&nbsp;"</span><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span><span data-custom-class="body_text">"&nbsp;below.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We will consider and act upon any request in accordance with applicable data protection laws.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your&nbsp;<span data-custom-class="body_text"><span data-custom-class="body_text"><a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noopener noreferrer" data-custom-class="link">Member State data protection authority</a></span></span>&nbsp;or&nbsp;</span><a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" target="_blank" rel="noopener noreferrer" data-custom-class="link"><span data-custom-class="body_text">UK data protection authority</span></a><span data-custom-class="body_text">.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">If you are located in Switzerland, you may contact the&nbsp;<span data-custom-class="body_text"><span data-custom-class="body_text"><a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noopener noreferrer" data-custom-class="link">Federal Data Protection and Information Commissioner</a></span></span>.</span></div>
<div>&nbsp;</div>
<div id="withdrawconsent"><span data-custom-class="body_text"><strong><u>Withdrawing your consent:</u></strong>&nbsp;If we are relying on your consent to process your personal information,&nbsp;which may be express and/or implied consent depending on the applicable law,&nbsp;you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section&nbsp;"</span><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span><span data-custom-class="body_text">"&nbsp;below&nbsp;or updating your preferences.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,&nbsp;when applicable law allows,&nbsp;will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><u>Opting out of marketing and promotional communications:</u></strong><strong>&nbsp;</strong>You can unsubscribe from our marketing and promotional communications at any time by&nbsp;clicking on the unsubscribe link in the emails that we send,&nbsp;or by contacting us using the details provided in the section&nbsp;"</span><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span><span data-custom-class="body_text">"&nbsp;below. You will then be removed from the marketing lists. However, we may still communicate with you &mdash; for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="heading_2"><strong>Account Information</strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">If you would at any time like to review or change the information in your account or terminate your account, you can:</span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Log in to your account settings and update your user account.</span></li>
</ul>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><u>Cookies and similar technologies:</u></strong>&nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.&nbsp;For further information, please see our Cookie Notice:&nbsp;<span data-custom-class="body_text"><a href="https://www.softwareiq.io/cookies" target="_blank" data-custom-class="link">https://www.softwareiq.io/cookies</a>.</span></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">If you have questions or comments about your privacy rights, you may email us at&nbsp;software.iq@outlook.com.</span></div>
<div>&nbsp;</div>
<div id="DNT"><span id="control"><strong><span data-custom-class="heading_1">11. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for&nbsp;recognizing&nbsp;and implementing DNT signals has been&nbsp;finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for&nbsp;recognizing&nbsp;or&nbsp;honoring&nbsp;DNT signals, we do not respond to them at this time.</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div id="uslaws"><span id="control"><strong><span data-custom-class="heading_1">12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>If you are a resident of&nbsp;California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.</em></span></div>
<div>&nbsp;</div>
<div><strong><span data-custom-class="heading_2">Categories of Personal Information We Collect</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We have collected the following categories of personal information in the past twelve (12) months:</span></div>
<div>&nbsp;</div>
<table>
<tbody>
<tr>
<td><span data-custom-class="body_text"><strong>Category</strong></span></td>
<td><span data-custom-class="body_text"><strong>Examples</strong></span></td>
<td><span data-custom-class="body_text"><strong>Collected</strong></span></td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">A. Identifiers</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</span></div>
</td>
<td>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">YES</span></div>
<div>&nbsp;</div>
</td>
</tr>
</tbody>
</table>
<div>&nbsp;</div>
<table>
<tbody>
<tr>
<td>
<div><span data-custom-class="body_text">B. Personal information as defined in the California Customer Records statute</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Name, contact information, education, employment, employment history, and financial information</span></div>
</td>
<td>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">YES</span></div>
<div>&nbsp;</div>
</td>
</tr>
</tbody>
</table>
<div>&nbsp;</div>
<table>
<tbody>
<tr>
<td>
<div><span data-custom-class="body_text">C. Protected classification characteristics under state or federal law</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">NO</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">D. Commercial information</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Transaction information, purchase history, financial details, and payment information</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">YES</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">E. Biometric information</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Fingerprints and voiceprints</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">NO</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">F. Internet or other similar network activity</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Browsing history, search history, online&nbsp;behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">NO</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">G. Geolocation data</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Device location</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">YES</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">H. Audio, electronic, sensory, or similar information</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Images and audio, video or call recordings created in connection with our business activities</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">NO</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">I. Professional or employment-related information</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">YES</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">J. Education Information</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Student records and directory information</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">NO</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td>
<div><span data-custom-class="body_text">K. Inferences drawn from collected personal information</span></div>
</td>
<td>
<div><span data-custom-class="body_text">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics</span></div>
</td>
<td>
<div>&nbsp;</div>
<div data-custom-class="body_text"><span data-custom-class="body_text">NO</span></div>
<div>&nbsp;</div>
</td>
</tr>
<tr>
<td><span data-custom-class="body_text">L. Sensitive personal Information</span></td>
<td>&nbsp;</td>
<td>
<div data-empty="true">&nbsp;</div>
<div data-custom-class="body_text" data-empty="true">NO</div>
<div data-empty="true">&nbsp;</div>
</td>
</tr>
</tbody>
</table>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</span></div>
<ul>
<li data-custom-class="body_text">Receiving help through our customer support channels;</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text">Participation in customer surveys or contests; and</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text">Facilitation in the delivery of our Services and to respond to your inquiries.</li>
</ul>
<div><span data-custom-class="body_text">We will use and retain the collected personal information as needed to provide the Services or for:</span></div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Category A -&nbsp;As long as the user has an account with us</span></li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Category B -&nbsp;As long as the user has an account with us</span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Category&nbsp;D&nbsp;-&nbsp;As long as the user has an account with us</span></li>
</ul>
<div>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Category&nbsp;G&nbsp;-&nbsp;1 year</span></li>
</ul>
<div>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><span data-custom-class="body_text">Category&nbsp;I&nbsp;-&nbsp;As long as the user has an account with us</span></li>
</ul>
<div>
<div>
<div>
<div><strong><span data-custom-class="heading_2">Sources of Personal Information</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Learn more about the sources of personal information we collect in&nbsp;"</span><span data-custom-class="body_text">WHAT INFORMATION DO WE COLLECT?</span><span data-custom-class="body_text">"</span></div>
<div>&nbsp;</div>
<div><strong><span data-custom-class="heading_2">How We Use and Share Personal Information</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Learn about how we use your personal information in the section,&nbsp;"</span>HOW DO WE PROCESS YOUR INFORMATION?<span data-custom-class="body_text">"</span></div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><strong>Will your information be shared with anyone else?</strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section,&nbsp;"</span>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?<span data-custom-class="body_text">"</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be&nbsp;"selling"&nbsp;of your personal information.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We&nbsp;will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</span></div>
<div>&nbsp;</div>
<div><strong><span data-custom-class="heading_2">Your Rights</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:</span></div>
<ul>
<li data-custom-class="body_text"><strong>Right to know</strong>&nbsp;whether or not we are processing your personal data</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><strong>Right to access&nbsp;</strong>your personal data</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><strong>Right to correct&nbsp;</strong>inaccuracies in your personal data</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><strong>Right to request</strong>&nbsp;the deletion of your personal data</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><strong>Right to obtain a copy&nbsp;</strong>of the personal data you previously shared with us</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><strong>Right to non-discrimination</strong>&nbsp;for exercising your rights</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text"><strong>Right to opt out</strong>&nbsp;of the processing of your personal data if it is used for targeted advertising&nbsp;(or sharing as defined under California&rsquo;s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</li>
</ul>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Depending upon the state where you live, you may also have the following rights:</span></div>
<ul>
<li data-custom-class="body_text">Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including&nbsp;California's and Delaware's&nbsp;privacy law)</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text">Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon&rsquo;s privacy law)</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text">Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California&rsquo;s privacy law)</li>
</ul>
<div>&nbsp;</div>
<ul>
<li data-custom-class="body_text">Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida&rsquo;s privacy law)</li>
</ul>
<div>&nbsp;</div>
<div><strong><span data-custom-class="heading_2">How to Exercise Your Rights</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">To exercise these rights, you can contact us&nbsp;by visiting&nbsp;<a href="https://www.softwareiq.io/contactus" target="_blank" data-custom-class="link">https://www.softwareiq.io/contactus</a>,&nbsp;</span><span data-custom-class="body_text"><span data-custom-class="body_text">by emailing us at&nbsp;software.iq@outlook.com,&nbsp;</span></span><span data-custom-class="body_text">or by referring to the contact details at the bottom of this document.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Under certain US state data protection laws, you can designate an&nbsp;authorized&nbsp;agent to make a request on your behalf. We may deny a request from an&nbsp;authorized&nbsp;agent that does not submit proof that they have been validly&nbsp;authorized&nbsp;to act on your behalf in accordance with applicable laws.</span></div>
<div>&nbsp;</div>
<div><strong><span data-custom-class="heading_2">Request Verification</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">If you submit the request through an&nbsp;authorized&nbsp;agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.</span></div>
<div>&nbsp;</div>
<div><span data-custom-class="heading_2"><strong>Appeals</strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at&nbsp;software.iq@outlook.com. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.</span></div>
<div>&nbsp;</div>
<div><strong><span data-custom-class="heading_2">California&nbsp;"Shine The Light"&nbsp;Law</span></strong></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the&nbsp;"Shine The Light"&nbsp;law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section&nbsp;"</span><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span><span data-custom-class="body_text">"</span></div>
<div>&nbsp;</div>
<div id="policyupdates"><span id="control"><strong><span data-custom-class="heading_1">13. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated&nbsp;"Revised"&nbsp;date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></div>
<div>&nbsp;</div>
<div id="contact"><span id="control"><strong><span data-custom-class="heading_1">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">If you have questions or comments about this notice, you may&nbsp;contact our Data Protection Officer (DPO)<span data-custom-class="body_text">&nbsp;by email at&nbsp;</span><span data-custom-class="body_text">software.iq@outlook.com</span>,<span data-custom-class="body_text">&nbsp;by phone at&nbsp;6303382140,</span><span data-custom-class="body_text">&nbsp;or&nbsp;</span><span data-custom-class="body_text">contact us by post at:</span></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text"><span data-custom-class="body_text">SoftwareIQ, LLC</span></span></div>
<div><span data-custom-class="body_text">Data Protection Officer</span></div>
<div><span data-custom-class="body_text">__________</span></div>
<div><span data-custom-class="body_text">Seattle<span data-custom-class="body_text">,&nbsp;WA</span>&nbsp;98101</span></div>
<div><span data-custom-class="body_text">United States</span></div>
<div>&nbsp;</div>
<div id="request"><span id="control"><strong><span data-custom-class="heading_1">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></span></div>
<div>&nbsp;</div>
<div><span data-custom-class="body_text">Based on the applicable laws of your country&nbsp;or state of residence in the US, you may&nbsp;have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please&nbsp;</span><span data-custom-class="body_text">visit:&nbsp;<a href="https://www.softwareiq.io/contactus" target="_blank" data-custom-class="link">https://www.softwareiq.io/contactus</a></span><span data-custom-class="body_text">.</span></div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</Box>
    </>
    )
}
export default Privacy