import React from 'react';
import { Checkbox, Box, Typography, MenuItem, Select, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FilterTableFields = ({ checked, handleToggle, items }) => {
  const theme = useTheme();

  return (
    <Box
      size="small"
      sx={{
        height: '30px',
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        minWidth: '240px',
        marginTop: '2px',
        maxWidth: '100%',
        flex: 1,
      }}
    >
      <Select
        size="small"
        sx={{ backgroundColor: theme.tableHeader.highlight, width: '308px' }}
        multiple
        value={checked} // Use checked here
        renderValue={() => (
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: '12px', paddingTop: '3px' }}>
            Filter by Category
          </Typography>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250,
              width: 'auto',
            },
          },
        }}
      >
        {/* Header Row */}
        <Box
          sx={{
            padding: '4px',
            borderBottom: '1px solid',
            borderColor: theme.palette.divider,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ color: theme.palette.text.secondary,fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }}>Category</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="caption" sx={{textAlign: 'center',color: theme.palette.text.secondary,width:'30px',marginRight:'18px' }}>Cap Weight</Typography>
            <Typography variant="caption" sx={{textAlign: 'center',color: theme.palette.text.secondary,width:'30px',marginRight:'18px' }}>Equal Weight</Typography>
          </Box>
        </Box>

        {/* Items List */}
        {items.map((item) => (
          <MenuItem
            key={item.id}
            sx={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <ListItemText
              primary={
                <Typography sx={{ color: theme.palette.text.secondary }} variant="body2">
                  {item.label}
                </Typography>
              }
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                value={item.id}
                onChange={handleToggle}
                checked={checked.indexOf(item.id) !== -1}
              />
              <Checkbox
                value={item.idEW}
                onChange={handleToggle}
                checked={checked.indexOf(item.idEW) !== -1}
              />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default FilterTableFields;
