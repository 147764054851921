import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import '../index.css';
import ReactGA from 'react-ga4';
import { useTheme } from '@mui/material/styles';
import { Grid, Paper, Tabs, Tab, Box } from '@mui/material';

// Import your JSON data
import weeksData from '../data/tables/earningsdate.json';

function EarningsCalendar() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const location = useLocation(); // If using React Router

  useEffect(() => {
    ReactGA.send({ hittype: 'pageview', page: location.pathname });
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderLogos = (tickers = []) => { // Default to an empty array
    if (!tickers || tickers.length === 0) {
      return <Box sx={{ paddingBottom: '25px', paddingLeft: '15px', paddingRight: '15px', fontSize: ['12px', '16px', '16px'] }}>No reported earnings on this date</Box>;
    }
    const halfwayIndex = Math.ceil(tickers.length / 2);
    const firstColumn = tickers.slice(0, halfwayIndex);
    const secondColumn = tickers.slice(halfwayIndex);

    return (
      <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
        <Grid item xs={6}>
          <Box sx={{ maxWidth:'150px',paddingLeft:'4px',display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {firstColumn.map((ticker, index) => (
              <Box key={index} sx={{ paddingBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link to={`/${ticker}`}>
                  <img
                    src={`/logos/${ticker}/${theme.palette.mode === 'dark' ? 'logodark.png' : 'logo.png'}`}
                    alt={`Icon`}
                    style={{ maxWidth: '95%', maxHeight: '40px' }}
                  />
                </Link>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ maxWidth:'150px', paddingLeft:'2px',paddingRight:'4px',display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {secondColumn.map((ticker, index) => (
              <Box key={index} sx={{ paddingBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link to={`/${ticker}`}>
                  <img
                    src={`/logos/${ticker}/${theme.palette.mode === 'dark' ? 'logodark.png' : 'logo.png'}`}
                    alt={`Icon`}
                    style={{ maxWidth: '95%', maxHeight: '40px' }}
                  />
                </Link>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Paper sx={{ backgroundColor: theme.background.backgroundColor }} elevation={3}>
        <Box sx={{ display:'flex', justifyContent:'center',paddingTop: '12px', marginLeft: '2%', paddingBottom: '12px', color: theme.palette.primary.main, fontSize: '36px', fontWeight: '600' }}>Earnings Calendar</Box>
      </Paper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
      <Box sx={{ minHeight: '70vh' }}>
        <div className="spacer"></div>
        <Paper elevation={2} sx={{ display:'flex', justifyContent:'center', marginLeft: '1%', maxWidth:'100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            {weeksData.weeks.map((week, index) => (
              <Tab key={index} label={`Week of ${week.week}`} />
            ))}
          </Tabs>
        </Paper>
        <div className="spacer"></div>
        <Paper sx={{ minHeight: '525px', paddingBottom: '30px', marginLeft: '1%', paddingLeft: '1%' ,maxWidth:'100%' }}>
          <div className="spacer"></div>
          <Box sx={{ marginBottom: '10px', color: theme.palette.primary.main, fontSize: '24px', fontWeight: '600', paddingRight: '2% ' }}>Earnings Week of {weeksData.weeks[value]?.week}</Box>
          <div className="spacer"></div>
          <Grid container spacing={1} sx={{ alignItems: 'stretch' }}>
            {weeksData.weeks[value]?.weeklyDates.map((day, index) => (
              <Grid key={index} item xs={2.35} md={2.35}>
                <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                  <Box sx={{ width: '100%', fontSize: ['12px', '16px', '18px'], textAlign: 'center', marginBottom: '20px', fontWeight: 'bold', backgroundColor: theme.tableHeader.color }}>{day.date}</Box>
                  {renderLogos(day.tickers)}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
      </Box>
    </>
  );
}

export default EarningsCalendar;