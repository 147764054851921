import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const words = ['Hedge Funds.','Venture Capital.', 'Business Development.', 'Strategic Finance.', 'Investment Banking.', 'Founders.'];

const TypingAnimation = () => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const typingSpeed = 50;
    const deletingSpeed = 80;
    const pauseDuration = 1800; // Pause after the word is fully typed
  
    useEffect(() => {
      let timeout;
  
      const handleTyping = () => {
        const currentWord = words[currentWordIndex];
  
        if (!isDeleting) {
          // Typing forward
          const nextText = currentWord.slice(0, displayedText.length + 1);
          setDisplayedText(nextText);
  
          if (nextText === currentWord) {
            // Pause after typing the full word
            timeout = setTimeout(() => setIsDeleting(true), pauseDuration);
          } else {
            timeout = setTimeout(handleTyping, typingSpeed);
          }
        } else {
          // Deleting backward
          const nextText = currentWord.slice(0, displayedText.length - 1);
          setDisplayedText(nextText);
  
          if (nextText === '') {
            // Once fully deleted, move to the next word and start typing
            setIsDeleting(false);
            setCurrentWordIndex((prev) => (prev + 1) % words.length);
            // Set displayedText to empty to prevent undefined
            setDisplayedText(''); 
          } else {
            timeout = setTimeout(handleTyping, deletingSpeed);
          }
        }
      };
  
      // Start typing/deleting process
      timeout = setTimeout(handleTyping, isDeleting ? deletingSpeed : typingSpeed);
  
      return () => clearTimeout(timeout);
    }, [displayedText, currentWordIndex, isDeleting]);
  
    return (
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          alignSelf: 'left',
          textAlign: 'left',
          fontSize: { xs: '1rem',sm:'1.2rem', md: '1.4rem' },
          paddingTop:'2vh',
          paddingBottom:'2vh',
          lineHeight: 1.8,
          fontWeight: 300,
          letterSpacing: '0.5px',
        }}
      >
        Explore a purpose-built industry research platform for {displayedText}
      </Typography>
    );
  };
  

export default TypingAnimation;