import React, { useState, useEffect } from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {FaGitAlt,FaUserSecret,FaCloud,FaCogs,FaStore,FaDatabase, FaIndustry, FaDesktop, FaBuilding,FaCoins,FaFileInvoice,FaComments,FaCode,FaServer,FaShieldAlt, FaUsers,FaHammer,FaNetworkWired} from 'react-icons/fa';

import {Icon,useMediaQuery,Paper,CircularProgress, Box, Typography,Collapse,IconButton } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import TableNews from '../components/TableNews';
import TableMetrics from '../components/TableMetrics';

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const SecurityAnalysisAbout = ({ticker, open, handleToggleCollapse}) => {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { logout,isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [summaryData,setSummaryData] = useState({})
  const [news,setNews] = useState([])
  const [description,setDescription] = useState("")
  const [softwareCategory,setSoftwareCategory] = useState("")
  const [marketCategory,setMarketCategory] = useState("")
  const [deployment,setDeployment] = useState("")
  const [techStack,setTechStack] = useState("")
  const [keyStats,setKeyStats] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
  

  }, []); 
  const apiUrl = 'https://mwl26uv0m7.execute-api.us-east-1.amazonaws.com/prod/validate'; // Use the API Gateway URL from CDK output
      const fileKey = `securityanalysis/news/${ticker}.json`
      useEffect(() => {
        const fetchAuthData = async () => {
          if (!isAuthenticated) return; // Wait until the user is authenticated
        
          setLoading(true);
          try {
            // Retrieve the token from Auth0
            const token = await getAccessTokenSilently({
              audience: "https://api.softwareiq.io", // Replace with your Auth0 audience
              scope: "read:news", // Replace with your API's required scopes
            });
        
            // Make the API call with the token and fileKey
            const response = await axios.get(`${apiUrl}?fileKey=${fileKey}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
              },
            });
            const summaryData = response.data.data

            const deployment = summaryData['Deployment']
            const techStack = summaryData['Tech Stack']
            const keyStats = summaryData['Key Stats']
            setNews(summaryData.News)
            setDescription(summaryData.Description)
            setSoftwareCategory(summaryData['Sub Industry'])
            setMarketCategory(summaryData['Category'])
            setDeployment(summaryData['Deployment'])
            setTechStack(summaryData['Tech Stack'])
            setKeyStats(summaryData['Key Stats'])


  
          } catch (error) {
            console.error("Error fetching data", error);
        
            if (
              error.message.includes("Missing Refresh Token") ||
              error.error === "login_required"
            ) {
              // Force the user to sign out
              logout({ returnTo: window.location.origin });
            } else {
              setError("Error fetching data");
            }
          } finally {
            setLoading(false);
          }
        };
    
        fetchAuthData();
      }, [isAuthenticated,ticker, getAccessTokenSilently]);
  
  
  const category = [
    {
      id: 1,
      subIndustry:'Application',
      filter: 'Application',
      label: 'Application Software',
      icon: <FaDesktop />,
    },
    {
      id: 10,
      subIndustry:'Application',
      filter: 'CRM, Marketing, and Support',
      label: 'CRM, Marketing & Support',
      icon: <FaStore />,
    },
    {
      id: 11,
      subIndustry:'Application',
      filter: 'HR, Finance & Supply Chain',
      label: 'HR, Finance & SCM',
      icon: <FaUsers />,
    },
    {
      id: 12,
      subIndustry:'Application',
      filter: 'Productivity & Content Creation',
      label: 'Productivity',
      icon: <FaFileInvoice />,
    },
    {
      id: 2,
      subIndustry:'Infrastructure',
      filter: 'Infrastructure',
      label: 'Infrastructure Software',
      icon: <FaCogs />,
    },
    
    {
      id: 20,
      subIndustry:'Infrastructure',
      filter: 'Database & Data Platforms',
      label: 'Database & Data Platforms',
      icon: <FaDatabase />,
    },
    {
      id: 21,
      subIndustry:'Infrastructure',
      filter: 'Dev Tools & Low Code',
      label: 'Dev Tools & Low Code',
      icon: <FaCode />,
    },
    {
      id: 22,
      subIndustry:'Infrastructure',
      filter: 'IT Operations',
      label: 'IT Operations',
      icon: <FaServer />,
    },
    {
      id: 23,
      subIndustry:'Infrastructure',
      filter: 'Security & Endpoint Management',
      label: 'Security & UEM',
      icon: <FaShieldAlt />,
    },
    {
      id: 24,
      subIndustry:'Infrastructure',
      filter: 'Networking & CDN',
      label: 'Networking & CDN',
      icon: <FaNetworkWired />,
    },
    {
      id: 25,
      subIndustry:'Infrastructure',
      filter: 'CPaaS',
      label: 'CPaaS',
      icon: <FaComments />,
    },
    {
      id: 3,
      subIndustry:'Vertical',
      filter: 'Vertical',
      label: 'Vertical Software',
      icon: <FaIndustry />,
    },
    {
      id: 30,
      subIndustry:'Vertical',
      filter: 'Financials',
      label: 'Financials',
      icon: <FaCoins />,
    },
    {
      id: 31,
      subIndustry:'Vertical',
      filter: 'Engineering & Industrials',
      label: 'Engineering & Industrials',
      icon: <FaHammer />,
    },
    {
      id: 32,
      subIndustry:'Vertical',
      filter: 'Other Vertical',
      label: 'Other Vertical',
      icon: <FaIndustry />,
    },
    {
      id: 4,
      subIndustry:'Diversified',
      filter: 'Diversified',
      label: 'Diversified Software',
      icon: <FaBuilding />,
    },
  ];
  const findIcon = (subIndustry, filter) => {
    const categoryItem = category.find(item => item.subIndustry === subIndustry && item.filter === filter);
    return categoryItem ? categoryItem.icon : null; // Return the icon or null if not found
  };
  const shouldWrap2 = useMediaQuery('(max-width: 600px)');
  
  const theme = useTheme();
  const [table, setTable] = useState([]);
  
  const tableContainerWidth = 1500


  
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  // Function to handle button clicks and update the selected button state
  
  
  const descriptionParagraphs = (description || '')
    .replace(/[\u2018\u2019\u201B\uFFFD]/g, "'") // Normalize non-standard apostrophes
    .split('\n') // Split by new lines for paragraphs
    .filter(paragraph => paragraph.trim() !== ''); // Remove empty paragraphs

  const categoryIcon = findIcon(softwareCategory, marketCategory);
  const softwareCategoryIcon = findIcon(softwareCategory, softwareCategory);
  const deploymentIcon = deployment === 'On-Prem/Hybrid' ? <FaServer/>:<FaCloud/>
  const openSourceIcon = techStack === 'Open Source' ? <FaGitAlt/>:<FaUserSecret/>
  return(
    
  <Box sx={{ overflow:'auto',height: '100%',maxHeight: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
    <Box
          sx={{
            height:'auto',
            backgroundColor: theme.background.backgroundColor,
            marginBottom: '0px', // Ensure no gap
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            boxShadow: theme.palette.mode === 'light'
          ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
          : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
          }}
          elevation={0} // No default elevation, custom shadow
        >
      <Collapse in={open} sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
                <Box elevation={0} sx={{ paddingBottom: '2px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', width:'100%',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          



         </Box>



          <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '10%',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        </Box>
        </Collapse>
        

    </Box>
    {loading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress /> {/* This is the MUI spinner */}
    </Box>):
         (       
    <Box sx={{ overflow:'auto',flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    {!isMobile&&<Box  sx={{flexWrap:'wrap',marginLeft:'1%', paddingBottom: '0px', maxWidth: tableContainerWidth + 50, paddingTop: '0px', marginTop: '4px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paper sx= {{width:'160px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'12px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Software Category
        </Typography>
      <Box sx={{display:'flex',height:'40px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      {softwareCategoryIcon && <Icon sx={{}}>{softwareCategoryIcon}</Icon>}
      <Typography sx={{fontSize:'12px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {softwareCategory} Software
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'160px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'12px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Market Category
        </Typography>
      <Box sx={{display:'flex',height:'40px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      {categoryIcon && <Icon sx={{}}>{categoryIcon}</Icon>}
      <Typography sx={{fontSize:'12px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {marketCategory}
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'160px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'12px', fontWeight:600,textAlign: 'center' }} variant='body1'>
        Deployment Model
        </Typography>
      <Box sx={{display:'flex',height:'40px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      { <Icon sx={{}}>{deploymentIcon}</Icon>}
      <Typography sx={{fontSize:'12px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {deployment}
        </Typography>
        </Box>
      </Paper>
      <Paper sx= {{width:'160px', margin:'6px'}}>
      <Typography sx={{backgroundColor:theme.tableHeader.color,fontSize:'12px', fontWeight:600,textAlign: 'center' }} variant='body1'>
      Source Model
        </Typography>
      <Box sx={{display:'flex',height:'40px',paddingLeft:'6px',paddingRight:'6px',justifyContent:'flex-start', maxWidth:'200px',alignItems:'center', justifyContent: 'center'}}>
      { <Icon sx={{}}>{openSourceIcon}</Icon>}
      <Typography sx={{fontSize:'12px', fontWeight:300,marginLeft:'6px',marginRight:'6px',textAlign: 'center' }} variant='body1'>
        {techStack}
        </Typography>
        </Box>
      </Paper>
      

      <Box>

      </Box>
   
        </Box>}
              {/* Main content grid */}
              <Box
            sx={{
              overflow:'auto',
              width:'95%',
              maxWidth:'1700px',
              height:'100%',
              display: 'grid',
              gridTemplateColumns: isMobile ? '1fr' : '1fr 2fr',
              gap: 2,
              p: 2,
            }}
          >
            {/* Left column */}
            <Box sx={{overflow:'auto',display: 'flex', flexDirection: 'column', gap: 0}}>
              <TableMetrics sx={{}}title="Key Stats" keyStats={keyStats} />
             {/* Description Box */}
              <Paper sx={{ marginTop:'12px',px: 2,py:.5, flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <Typography sx={{ 
            color: theme.palette.primary.main, 
            fontSize: '20px', 
            fontWeight: '300 ' , // Use !important to enforce the style
            textAlign: 'left', // Ensure text is left-aligned
            paddingLeft: '0.0%',
            mb: 0 }}>
                  Company Description
                </Typography>
                <Box
                  sx={{
                    overflowY: 'auto', // Scroll only vertically
                    flex: 1, // Take up available space
                  }}
                >
                  {descriptionParagraphs.map((paragraph, index) => (
                    <Typography key={index} variant="body2" sx={{ paddingRight:'10px',color:theme.palette.text.secondary,mb: 1, fontStyle: 'italic' }}>
                      {paragraph}
                    </Typography>
                  ))}
                </Box>
              </Paper>
            </Box>

            {/* Right column */}
            <Box sx={{height:'100%'}}>
              <TableNews theme={theme} newsData={news} />
            </Box>
          </Box>


        </Box>)}
    
  </Box>
      )}
      export default SecurityAnalysisAbout;