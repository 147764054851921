import React, { useState, useEffect,useRef } from 'react';
import '../index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import {useMediaQuery,Collapse, Box,IconButton, CircularProgress } from '@mui/material';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ButtonTimeFrame from '../components/ButtonTimeFrame';
import ButtonFormatValues from '../components/ButtonFormatValues';
import FilterSlider from '../components/FilterSlider';
import TableModel from '../components/TableModel';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';


const NewStockPage = ({ticker,open,handleToggleCollapse}) => {
  const { logout,isAuthenticated, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
  const theme = useTheme();
  
  const tableContainerRef = useRef(null);
  const [error, setError] = useState(null);
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [alignment, setAlignment] = React.useState('quarterly');
  const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };
  const [alignmentScale, setAlignmentScale] = React.useState('M');

  const sortOrder=['ARR','Cloud ARR','Non-Cloud ARR','Calculated Billings','Current Bookings','Bookings','Current RPO','RPO','Non-Cloud Revenue','Cloud Revenue','Revenue'
    ,'COGs','Gross Margin','SG&A','Sales & Marketing','Research & Development','General & Administrative','Operating Income','Net Income','Operating Cash Flow','Capital Expenditures',
      'Free Cash Flow','Shares Outstanding','Customers','Customers >$100k','Customers >$1M','Payback Period','Cloud Mix']

  const apiUrl = 'https://mwl26uv0m7.execute-api.us-east-1.amazonaws.com/prod/validate'; // Use the API Gateway URL from CDK output
  const fileKey = `securityanalysis/financial/${ticker}.json`
  useEffect(() => {
        const fetchAuthData = async () => {
          if (!isAuthenticated) return; // Wait until the user is authenticated
        
          setLoading(true);
          try {
            // Retrieve the token from Auth0
            const token = await getAccessTokenSilently({
              audience: "https://api.softwareiq.io", // Replace with your Auth0 audience
              scope: "read:news", // Replace with your API's required scopes
            });
        
            // Make the API call with the token and fileKey
            const response = await axios.get(`${apiUrl}?fileKey=${fileKey}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
              },
            });
            const financialData = response.data.data
            const dynamicDataAnnual = financialData.annualData
            const dynamicDataQtr = financialData.quarterlyData
  
            if (Array.isArray(dynamicDataAnnual.data)) {
              setAnnualData(dynamicDataAnnual.data);
              setAnnualMapping(dynamicDataAnnual.mappingTable);
              // Check the last revenue value and set the scale to 'B' if it's greater than $10B
              const revenueData = dynamicDataAnnual.data.find(item => item.field === 'Revenue');
              if (revenueData) {
                const lastRevenue = revenueData[Object.keys(revenueData).pop()]; // Get the last value
                if (lastRevenue > 2000000000) { // Check if the last revenue is greater than $10B
    
                  setAlignmentScale('B');
                }
              }
            }
            else {
              console.error('Unexpected data format:', dynamicDataAnnual);
            } 
            if (Array.isArray(dynamicDataQtr.data)) {
              setQtrData(dynamicDataQtr.data);
              setQtrMapping(dynamicDataQtr.mappingTable);
              setReportingCurrency(dynamicDataQtr.currency);
            }
            else {
              console.error('Unexpected data format:', dynamicDataQtr);
            }
          } catch (error) {
            console.error("Error fetching data", error);
        
            if (
              error.message.includes("Missing Refresh Token") ||
              error.error === "login_required"
            ) {
              // Force the user to sign out
              logout({ returnTo: window.location.origin });
            } else {
              setError("Error fetching data");
            }
          } finally {
            setLoading(false);
          }
        };
    
        fetchAuthData();
      }, [isAuthenticated,ticker, getAccessTokenSilently]);
      useEffect(() => {

        if (alignment === "annual"){
          if (Array.isArray(annualData)) {
            // Filter data based on fields present in the sortOrder
            let filteredData = annualData.filter(item => sortOrder.includes(item.field));
    
            // Sort filteredData based on the custom sortOrder
            filteredData = filteredData.sort((a, b) => {
              const indexA = sortOrder.indexOf(a.field);
              const indexB = sortOrder.indexOf(b.field);
              return indexA - indexB;
            });
          
          setData(filteredData)
          setMapping(annualMapping)
        }}
        else{
          if (Array.isArray(qtrData)) {
            // Filter data based on fields present in the sortOrder
            let filteredData = qtrData.filter(item => sortOrder.includes(item.field));
    
            // Sort filteredData based on the custom sortOrder
            filteredData = filteredData.sort((a, b) => {
              const indexA = sortOrder.indexOf(a.field);
              const indexB = sortOrder.indexOf(b.field);
              return indexA - indexB;
            });
    
          setData(filteredData)
          setMapping(qtrMapping)
        }}
      }, [loading,alignment, ticker]);  // Dependencies for useEffect
  const [annualData, setAnnualData] = useState([]);
  const [qtrData, setQtrData] = useState([]);
  const [annualMapping,setAnnualMapping] = useState([]);
  const [qtrMapping,setQtrMapping] = useState([]);
  const [mappingArray,setMapping] = useState([]); 
  const [reportingCurrency,setReportingCurrency] = useState(["USD"]);
  const calculateMaxSliderValue = () =>{ 
    if (alignment === 'annual'){
      return Math.min(mappingArray.length,20)
    }
    else
    {
      return Math.min(mappingArray.length,20)
    }
  }
  useEffect(() => {
    const maxSliderValue = calculateMaxSliderValue();
    const initialSliderValue = isMobile?2:isTablet?Math.min(5, maxSliderValue):Math.min(8, maxSliderValue);
  setValue(initialSliderValue);
  }, [mappingArray]);
  const maxSliderValue = calculateMaxSliderValue();
  const initialSliderValue = Math.max(6, maxSliderValue);
  
  const [sliderValue, setValue] = useState(initialSliderValue);
  // Function to handle button clicks and update the selected button state
  const slicedMapping = mappingArray.slice(-sliderValue)
  

  const handleChangeScale = (event, newAlignment) => {
    setAlignmentScale((prevAlignment) => {
      // If the newAlignment is already selected, return the previous alignment to keep it selected
      if (prevAlignment === newAlignment) {
        return prevAlignment;
      }
      // Otherwise, update the alignment to the new value
      return newAlignment;
    });
  };
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);

  };
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = () => {
    if (sliderValue < 2 || isNaN(sliderValue)) {
      setValue(2);
    } else if (sliderValue > maxSliderValue) {
      setValue(maxSliderValue);
    }
  };

  

 
  

  return(
    <Box sx={{ height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
      <Box
      sx={{
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
        <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
          <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <Box sx={{ display: 'flex', width:'900px',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              maxWidth: '1500px',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile?'100%':'65px',
                width: isMobile?'100%':'65px',
                fontSize: '12px',
                textAlign: isMobile?'left':'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Dashboard Filters
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <ButtonTimeFrame alignment={alignment} handleChange={handleChange}/>
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <ButtonFormatValues alignment={alignmentScale} handleChange={handleChangeScale}/>
              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '1%', marginRight: '1%', flexShrink: 1 }}>
              <FilterSlider period={alignment === "annual" ? "Years" : "Quarters"} sliderValue={sliderValue} maxSliderValue={maxSliderValue} handleSliderChange={handleSliderChange} handleInputChange={handleInputChange} handleBlur={handleBlur}/>
              </Box>

          
            </Box>
          </Box>
        </Box>


        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
    </Box>
    </Collapse>
    </Box>
    {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress /> {/* This is the MUI spinner */}
          </Box>):
          (       
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TableModel ticker={ticker} data={data} slicedMapping={slicedMapping} alignment={alignment} alignmentScale={alignmentScale} currency={reportingCurrency}/>
    </Box>)}
  </Box>
      )}
      export default NewStockPage;