import React, { useState, lazy, Suspense,useEffect } from "react";
import ReactGA from 'react-ga4';
import { useNavigate,useLocation } from 'react-router-dom';
import { Collapse,IconButton,Box, Tabs,Tab,Typography,MenuItem,Menu } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import itemListData from '../data/companylistsearch.json';
import LoginNotice from "../components/LoginNotice";
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0
import LockIcon from '@mui/icons-material/Lock';

const SecurityAnalysisAbout = lazy(() => import("../subpages/SecurityAnalysisAbout"));
const SecurityAnalysisKeyMetrics = lazy(() => import("../subpages/SecurityAnalysisKeyMetrics"));
const CoverageCompSheet = lazy(() => import("../subpages/CoverageCompSheet"));
const MetricsValuation= lazy(() => import("../subpages/MetricsValuation"));
const SecurityAnalysisModel= lazy(() => import("../subpages/SecurityAnalysisModel"));
const SecurityAnalysisPrivate= lazy(() => import("../subpages/SecurityAnalysisPrivate"));

function SecurityAnalysis({ticker}){
  
  const { isAuthenticated } = useAuth0(); // Use the useAuth0 hook
  //const isAuthenticated = true
  const isUserAuthenticated = ticker === "MDB" || isAuthenticated;

  
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  const [open, setOpen] = useState(!isMobile); // State to manage the open/closed state
  const location = useLocation(); // Get current URL location
  const [anchorEl, setAnchorEl] = useState(null);
  const openBread = Boolean(anchorEl);
  const [selectedButton, setSelectedButton] = useState(0);
  const [menuLabel, setMenuLabel] = useState("Market Coverage");
  const [heightUnit, setHeightUnit] = useState('vh');
  const logoSource = theme.palette.mode === 'dark'?`/logos/${ticker}/logodark.png`:`/logos/${ticker}/logo.png`;
  const iconSource = theme.palette.mode === 'dark'?`/logos/${ticker}/icondark.png`:`/logos/${ticker}/icon.png`;
  
  // Function to handle button clicks and update the selected button state

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);

  const findCategoryByTicker = (ticker) => {
    for (let company of itemListData) {
      if (company.ticker === ticker) {
        return company.category;
      }
    }
    return null; // Return null if ticker is not found
  };
  const findCompanyName = (ticker) => {
    for (let company of itemListData) {
      if (company.ticker === ticker) {
        return company.companyName;
      }
    }
    return null; // Return null if ticker is not found
  };
  const findHasCapTable = (ticker) => {
    for (let company of itemListData) {
      if (company.ticker === ticker) {
        return company.hasCapTable;
      }
    }
    return null; // Return null if ticker is not found
  };
  const findIsPrivate = (ticker) => {
    for (let company of itemListData) {
      if (company.ticker === ticker) {
        return company.isPrivate;
      }
    }
    return null; // Return null if ticker is not found
  };
  // Get the category for the given ticker
  const category = findCategoryByTicker(ticker);
  //if (!isAuthenticated) {
  //  return null; // Optionally, you can show a loading spinner or message here
  //}
  const companyName = findCompanyName(ticker);
  const hasCapTable = findHasCapTable(ticker);
  const isPrivate = findIsPrivate(ticker);
  useEffect(() => {
    const supportsSvh = CSS.supports('height', '1svh');
    if (supportsSvh) {
      setHeightUnit('svh');
    }
  }, []);
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };


    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, []);
  
  const shouldDisplayLogo = windowWidth > 790;
  // Function to map URL to tab index and menu label
  const getTabFromPath = (path) => {
    switch (path) {

      case `/company/${ticker.toLowerCase()}/valuation`:
          return { index: 4, label: 'Valuation Plot' };
      case `/company/${ticker.toLowerCase()}/valuation/`:
          return { index: 4, label: 'Valuation Plot' };
      case `/company/${ticker.toLowerCase()}/compsheet`:
            return { index: 3, label: 'Comp Sheet' };
      case `/company/${ticker.toLowerCase()}/compsheet/`:
            return { index: 3, label: 'Comp Sheet' };
      case `/company/${ticker.toLowerCase()}/news`:
            return { index: 0, label: 'News' };
      case `/company/${ticker.toLowerCase()}/news/`:
            return { index: 0, label: 'News' };
      case `/company/${ticker.toLowerCase()}/keymetrics`:
            if (!hasCapTable){
              return { index: 1, label: 'Key Metrics' };
            }
            return { index: 5, label: 'Private Financials' };
      case `/company/${ticker.toLowerCase()}/keymetrics/`:
            if (!hasCapTable){
              return { index: 1, label: 'Key Metrics' };
            }
            return { index: 5, label: 'Private Financials' };
      case `/company/${ticker.toLowerCase()}/model`:
            return { index: 2, label: 'Financial Model' };
      case `/company/${ticker.toLowerCase()}/model/`:
            return { index: 2, label: 'Financial Model' };
      case `/company/${ticker.toLowerCase()}/private`:
            return { index: 5, label: 'Private Financials' };
      case `/company/${ticker.toLowerCase()}/private/`:
            return { index: 5, label: 'Private Financials' };     
        default:
      return { index: null, label: 'Unknown' }; // Or whatever fallback you prefer
    }
  };
  // Set tab based on current URL on load
  useEffect(() => {
    const { index, label } = getTabFromPath(location.pathname);
    setSelectedButton(index);
    setMenuLabel(label);
  }, [location.pathname]);

  const handleToggleCollapse = () => {
    setOpen(!open);
  };
  // Function to handle category filter change

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};
const handleButtonClick = (index) => {
  setSelectedButton(index);
};
const handleMenuItemClick = (index, label, link) => {
  setSelectedButton(index);
  setMenuLabel(label);
  handleClose();
  navigate(link);
};
  // Filter the data based on the selected category

  return (
    <Box 
      sx={{ 
        height:  `calc(100${heightUnit} - 100px)`,
        marginBottom: '0px', 
        display: 'flex', 
        flexDirection: 'column', 
        flexGrow: 1, // Ensure this container can grow to fill available space
        overflowY: 'auto', 
      }}
    >

  <Box
    sx={{
      backgroundColor: theme.background.backgroundColor,
      marginBottom: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      boxShadow: open ? '0px':theme.palette.mode === 'light'
    ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
    : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
    
    }}
    elevation={0} // No default elevation, custom shadow
  >
  {(isMobile||!open) &&
  <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>

        <img 
              src= {iconSource}  
              alt={`${ticker} Icon`}
              style={{maxHeight:'18px',paddingRight:'.5%'}}
            />
    <Typography
        variant="body2"
        sx={{ color: theme.palette.primary.main, fontWeight:'normal',marginRight: '.5%', cursor: 'pointer' }}
      >
        {companyName}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.primary.main, fontWeight:'normal',marginRight: '.5%', cursor: 'pointer' }}
      >
        /
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',  // Make sure the entire box is clickable
          marginRight: '5%', 
        }}
        onClick={handleClick}
      >
        <Typography
          variant="body2"
          sx={{ 
            color: theme.palette.primary.main, 
            fontWeight: 'normal', 
            
          }}
        >
          {menuLabel}
        </Typography>
        <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
      </Box>
      
      <Menu
      anchorEl={anchorEl}
      open={openBread}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleMenuItemClick(0, 'News', `/company/${ticker.toLowerCase()}/news`)}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  News
                  {!isUserAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box>
      </MenuItem>
      {!hasCapTable&&<MenuItem onClick={() => handleMenuItemClick(1, 'Key Statistics', `/company/${ticker.toLowerCase()}/keymetrics`)}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Key Metrics
                  {!isUserAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box>
              </MenuItem>}
      {!hasCapTable&&<MenuItem onClick={() => handleMenuItemClick(2, 'Financial Model', `/company/${ticker.toLowerCase()}/model`)}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Financial Model
                  {!isUserAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box></MenuItem>}
      {!hasCapTable&&<MenuItem onClick={() => handleMenuItemClick(3, 'Comp Sheet', `/company/${ticker.toLowerCase()}/compsheet`)}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Comp Sheet
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box></MenuItem>}
      {!hasCapTable&&<MenuItem onClick={() => handleMenuItemClick(4, 'Valuation Plot', `/company/${ticker.toLowerCase()}/valuation`)}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Valuation Plot
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box></MenuItem>}
      {hasCapTable&&<MenuItem onClick={() => handleMenuItemClick(5, 'Private Financials', `/company/${ticker.toLowerCase()}/private`)}><Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Private Financials
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box></MenuItem>}
    </Menu>
    
    {!open&& <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              borderRadius:'5%',
              padding: "0px 2px",
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            <Typography variant="body2" sx={{color: theme.palette.text.secondary,fontStyle:'italic'}}>{(isMobile )?'Filters':'Expand Menu'}</Typography>
            {<ExpandMoreIcon />}
          </IconButton>}
          </Box>}
          {!isMobile&&<Collapse in={open} sx={{ width: '100%' }}>
          <Box  sx={{paddingTop:'4px',backgroundColor:theme.background.backgroundColor,display:'flex', justifyContent:'center'}} elevation={3} >
          <Box sx={{maxWidth:'100%',display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '4px', paddingBottom: '0px', marginLeft: '0%', color: theme.palette.primary.main, fontSize: '30px', fontWeight: '600' }}>
            Security Analysis
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '4px', paddingBottom: '0px', marginLeft: '0%', color: theme.palette.text.secondary, fontSize: '14px', fontWeight: '600',fontStyle:'italic' }}>
          {isPrivate ? (
                <span>Private: {companyName}</span>
              ) : (
                <span>{companyName}: {ticker}</span>
              )}
          </Box>
          <Box sx={{paddingTop:'2px',paddingLeft:'1%',paddingRight:'1%',maxWidth:'100%',display:'flex', justifyContent:'center'}}>
            <Box sx={{width:'600px',display:'flex', justifyContent:'center',borderBottom: `1px solid ${theme.palette.divider}`}}>
          <Tabs 
            scrollButtons={"auto"}
            variant={"scrollable"}
            allowScrollButtonsMobile 
            value={selectedButton} // Bind the Tabs component to the selected tab
            onChange={(e, newValue) => setSelectedButton(newValue)} // Update selectedButton when tab changes
              
              elevation={0} sx={{height:'30px',minHeight:'30px',borderBottom: `0px solid ${theme.palette.divider}`,maxWidth:'100%',marginLeft:'0%', backgroundColor: 'transparent' ,display:'flex', justifyContent:'center',
              '& .MuiTabs-scrollButtons': {
              color: theme.palette.text.secondary, // Set the scroll buttons color to match the theme
              '&:hover': {
                backgroundColor: theme.palette.action.hover, // Optional: add hover effect
              },
              '&.Mui-disabled': {
                opacity: 0.3, // Change disabled state opacity if needed
              }
            },}}>
              {true&&(<Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  News
                  {!isUserAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box>
              }
              value={0}
              style={{ textTransform: 'none' }}
                sx={{
                  fontSize: '13px',
                  padding: '0px 12px',
                  height:'28px',
                  minHeight:'28px'
                }}
                onClick={() => {
                  setSelectedButton(0);
                  navigate(`/company/${ticker.toLowerCase()}/news`); // Replace with your link
              }}
              >
              </Tab>)}
              {!hasCapTable&&(<Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Key Metrics
                  {!isUserAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box>
              }
              value={1}
              style={{ textTransform: 'none' }}
                sx={{
                  fontSize: '13px',
                  
                  padding: '0px 12px',
                  height:'28px',
                  minHeight:'28px'
                }}
                onClick={() => {
                  handleButtonClick(1);
                  navigate(`/company/${ticker.toLowerCase()}/keymetrics`); // Replace with your link
                }}
              >
              </Tab>)}
              {!hasCapTable&&(<Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Financial Model
                  {!isUserAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box>
              }
              value={2}
              style={{ textTransform: 'none' }}
                sx={{
                  fontSize: '13px',
                  
                  padding: '0px 12px',
                  height:'28px',
                  minHeight:'28px'
                }}
                onClick={() => {
                  handleButtonClick(2);
                  navigate(`/company/${ticker.toLowerCase()}/model`); // Replace with your link
                }}
              >
              </Tab>)}
              {!hasCapTable&&(<Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Comp Sheet
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box>
              }
              value={3}
              style={{ textTransform: 'none' }}
                sx={{
                  fontSize: '13px',
                  
                  padding: '0px 12px',
                  height:'28px',
                  minHeight:'28px'
                }}
                onClick={() => {
                  handleButtonClick(3);
                  navigate(`/company/${ticker.toLowerCase()}/compsheet`); // Replace with your link
                }}
              >
              </Tab>)}
              {!hasCapTable&&(<Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Valuation Plot
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box>
              }
              value={4}
              style={{ textTransform: 'none' }}
                sx={{
                  fontSize: '13px',
                  
                  padding: '0px 12px',
                  height:'28px',
                  minHeight:'28px'
                }}
                onClick={() => {
                  handleButtonClick(4);
                  navigate(`/company/${ticker.toLowerCase()}/valuation`); // Replace with your link
                }}
              >
              </Tab>)}
              {hasCapTable&&(<Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Private Financials
                  {!isAuthenticated && <LockIcon fontSize="smaller" sx={{ ml: 0.5 }} />}
                </Box>
              }
              value={5}
              style={{ textTransform: 'none' }}
                sx={{
                  fontSize: '13px',
                  
                  padding: '0px 12px',
                  height:'28px',
                  minHeight:'28px'
                }}
                onClick={() => {
                  handleButtonClick(5);
                  navigate(`/company/${ticker.toLowerCase()}/private`); // Replace with your link
                }}
              >
              </Tab>)}
            </Tabs>
            </Box>
            </Box>
            
            </Box>
            {shouldDisplayLogo &&<Box sx={{paddingTop:'5px',paddingLeft:'10px',paddingRight:'10px',maxWidth:'290px' }}>
            <img 
              src= {logoSource}  
              alt={`${ticker} Icon`}
              style={{maxHeight:'85px',maxWidth:'95%'}}
            />
          </Box>}
          </Box>
          </Box>
      </Collapse>}
      
    </Box>
    <Suspense >
        {selectedButton === 0 && isUserAuthenticated && <SecurityAnalysisAbout ticker={ticker} open={open} handleToggleCollapse={handleToggleCollapse} />}
        {selectedButton === 1 && isUserAuthenticated && !hasCapTable&& <SecurityAnalysisKeyMetrics ticker={ticker} open={open} handleToggleCollapse={handleToggleCollapse} />}
        {selectedButton === 2 && isUserAuthenticated && !hasCapTable&& <SecurityAnalysisModel ticker={ticker} open={open} handleToggleCollapse={handleToggleCollapse} />}
        {selectedButton === 3 && isAuthenticated && !hasCapTable&& <CoverageCompSheet categoryInput={[category]} open={open} handleToggleCollapse={handleToggleCollapse} />}
        {selectedButton === 4 && isAuthenticated && !hasCapTable&& <MetricsValuation categoryInput={[category]} open={open} handleToggleCollapse={handleToggleCollapse} />}
        {selectedButton === 5 && isAuthenticated && hasCapTable&& <SecurityAnalysisPrivate companyName={companyName} ticker={ticker} open={open} handleToggleCollapse={handleToggleCollapse} />}
        {(selectedButton === 0||selectedButton === 1||selectedButton === 2) &&  !isUserAuthenticated && <LoginNotice />}
        {(selectedButton === 3||selectedButton === 4||selectedButton === 5) &&  !isAuthenticated && <LoginNotice />}
      </Suspense>

</Box>
  );
}

export default SecurityAnalysis