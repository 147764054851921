import React, { useState } from 'react';
import { FaEllipsisH,FaCog,FaGraduationCap,FaCreditCard,FaMedkit, FaStore, FaDatabase, FaIndustry, FaDesktop, FaBuilding, FaCoins, FaFileInvoice, FaCode, FaServer, FaShieldAlt, FaUsers, FaHammer, FaNetworkWired } from 'react-icons/fa';
import { ListItemIcon, Checkbox, Box, Typography, IconButton, MenuItem, Select, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@mui/material/styles';

const FilterCategory = ({ selectedCategory, handleCategoryChange,hideDiversified }) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState({
    Application: false,
    Infrastructure: false,
    Vertical: false,
  });


  const categories =hideDiversified?[
    { id: 1, label: 'Application Software', icon: <FaDesktop />, subCategories: [
      { id: 10, label: 'CRM, Marketing & Support', icon: <FaStore /> },
      { id: 11, label: 'HR, Finance, Legal & SCM', icon: <FaUsers /> },
      { id: 12, label: 'Productivity & Content Creation', icon: <FaFileInvoice /> },
    ]},
    { id: 2, label: 'Infrastructure Software', icon: <FaCog />, subCategories: [
      { id: 20, label: 'Database & Data Platforms', icon: <FaDatabase /> },
      { id: 21, label: 'DevTools & IT Ops', icon: <FaCode /> },
      { id: 22, label: 'AI Tools, IaaS, APIs & Low Code', icon: <FaServer /> },
      { id: 23, label: 'Security & UEM', icon: <FaShieldAlt /> },
      { id: 24, label: 'Networking & CDN', icon: <FaNetworkWired /> },
    ]},
    { id: 3, label: 'Vertical Software', icon: <FaIndustry />, subCategories: [
      { id: 30, label: 'Financials', icon: <FaCoins /> },
      { id: 31, label: 'Engineering & Industrials', icon: <FaHammer /> },
      { id: 32, label: 'Health Care', icon: <FaMedkit /> },
      { id: 33, label: 'Consumer', icon: <FaCreditCard /> },
      { id: 34, label: 'Public Sector', icon: <FaGraduationCap /> },
      { id: 35, label: 'Other Vertical', icon: <FaEllipsisH /> },
    ]},
  ]: [
    { id: 1, label: 'Application Software', icon: <FaDesktop />, subCategories: [
      { id: 10, label: 'CRM, Marketing & Support', icon: <FaStore /> },
      { id: 11, label: 'HR, Finance, Legal & SCM', icon: <FaUsers /> },
      { id: 12, label: 'Productivity & Content Creation', icon: <FaFileInvoice /> },
    ]},
    { id: 2, label: 'Infrastructure Software', icon: <FaCog />, subCategories: [
      { id: 20, label: 'Database & Data Platforms', icon: <FaDatabase /> },
      { id: 21, label: 'DevTools & IT Ops', icon: <FaCode /> },
      { id: 22, label: 'AI Tools, IaaS, APIs & Low Code', icon: <FaServer /> },
      { id: 23, label: 'Security & UEM', icon: <FaShieldAlt /> },
      { id: 24, label: 'Networking & CDN', icon: <FaNetworkWired /> },
    ]},
    { id: 3, label: 'Vertical Software', icon: <FaIndustry />, subCategories: [
      { id: 30, label: 'Financials', icon: <FaCoins /> },
      { id: 31, label: 'Engineering & Industrials', icon: <FaHammer /> },
      { id: 32, label: 'Health Care', icon: <FaMedkit /> },
      { id: 33, label: 'Consumer', icon: <FaCreditCard /> },
      { id: 34, label: 'Public Sector', icon: <FaGraduationCap /> },
      { id: 35, label: 'Other Vertical', icon: <FaEllipsisH /> },
    ]},
    { id: 4, label: 'Diversified Software', icon: <FaBuilding /> },
  ];

  const handleExpandToggle = (category) => (e) => {
    e.stopPropagation();
    setExpanded((prev) => ({ ...prev, [category]: !prev[category] }));
  };


  return (
    <Box size="small" sx={{ height: '30px', width: '300px', display: 'flex', flexDirection: 'row' }}>
      <Select
        size="small"
        sx={{backgroundColor: theme.tableHeader.highlight,  width: '300px' }}
        multiple
        value={selectedCategory}
        onChange={handleCategoryChange}
        renderValue={() => {
          // Always return the filter text
          return (
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: '12px', paddingTop: '3px' }}>
              Filter by Category
            </Typography>
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250,
              width: 'auto',
            },
          },
        }}
      >
        {categories.flatMap((category) => {
          // Create an array of MenuItems for each category
          const menuItems = [
            <MenuItem key={category.id} value={category.id} sx={{ height: '40px' }}>
              <ListItemIcon>{category.icon}</ListItemIcon>
              <ListItemText primary={<Typography sx={{color: theme.palette.text.secondary}} variant="body2">{category.label}</Typography>} />
              {category.subCategories ? (
                <>
                  <IconButton size="small" onClick={handleExpandToggle(category.label)}>
                    {expanded[category.label] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                  <Checkbox
                    indeterminate={
                      (selectedCategory.includes(category.id) && !category.subCategories.every((sub) => selectedCategory.includes(sub.id))) ||
                      (!selectedCategory.includes(category.id) && category.subCategories.some((sub) => selectedCategory.includes(sub.id)))
                    }
                    size="small"
                    checked={selectedCategory.includes(category.id)}
                  />
                </>
              ) : (
                <Checkbox size="small" checked={selectedCategory.includes(category.id)} />
              )}
            </MenuItem>
          ];

          // Add subCategories if expanded
          if (expanded[category.label] && category.subCategories) {
            const subMenuItems = category.subCategories.map((subCategory) => (
              <MenuItem key={subCategory.id} value={subCategory.id} sx={{ height: '26px' }}>
                <Box sx={{ width: '18px' }} />
                <ListItemIcon>{subCategory.icon}</ListItemIcon>
                <ListItemText primary={<Typography sx={{color: theme.palette.text.secondary}} variant="body2">{subCategory.label}</Typography>} />
                <Checkbox size="small" checked={selectedCategory.includes(subCategory.id)} />
              </MenuItem>
            ));
            menuItems.push(...subMenuItems); // Add subMenuItems to the array
          }

          return menuItems; // Return the array of MenuItems
        })}
      </Select>
    </Box>
  );
};

export default FilterCategory;