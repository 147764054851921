import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const AboutUs = () => {
  const theme = useTheme();

  return (
    <Box 
      sx={{ 
        padding: '60px 20px',                    // Increased padding for better spacing
        maxWidth: '900px',                       // Set a max width to control text width
        margin: 'auto',                          // Center the container
        backgroundColor: theme.palette.background.paper, // Subtle background color
        borderRadius: '8px',                     // Rounded corners
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
      }}
    >
      {/* Main Title */}
      <Box sx={{ marginBottom: '40px' }}>
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            color: theme.palette.primary.main, 
            fontWeight: 700, 
            textTransform: 'uppercase',            // Uppercase for emphasis
            letterSpacing: '1px', 
          }}
        >
          About Us
        </Typography>
      </Box>

      {/* Mission and Vision Section */}
      <Box sx={{ marginBottom: '30px' }}>
        <Typography 
          variant="h5" 
          sx={{ fontWeight: 600, color: theme.palette.primary.main, marginBottom: '10px' }}
        >
          Mission and Vision
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ color: theme.palette.text.secondary, fontSize: '16px', lineHeight: 1.75 }}
        >
          Our mission is to revolutionize software investment research by providing unparalleled insights, data, and tools that empower investors, analysts, and industry professionals to make informed, data-driven decisions.
        </Typography>
      </Box>

      {/* What We Offer Section */}
      <Box sx={{ marginBottom: '30px' }}>
        <Typography 
          variant="h5" 
          sx={{ fontWeight: 600, color: theme.palette.primary.main, marginBottom: '10px' }}
        >
          What We Offer
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ color: theme.palette.text.secondary, fontSize: '16px', lineHeight: 1.75 }}
        >
          SoftwareIQ combines deep industry knowledge with advanced technology to deliver a research experience that's accurate, comprehensive, and easy to navigate. From market trends to financial data, we provide a platform that covers the full spectrum of software investment opportunities.
        </Typography>
      </Box>

      {/* Our Expertise Section */}
      <Box sx={{ marginBottom: '30px' }}>
        <Typography 
          variant="h5" 
          sx={{ fontWeight: 600, color: theme.palette.primary.main, marginBottom: '10px' }}
        >
          Our Expertise
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ color: theme.palette.text.secondary, fontSize: '16px', lineHeight: 1.75 }}
        >
          Built by a team of industry veterans and financial experts, SoftwareIQ is grounded in years of experience within the software sector and investment research. Our team understands the challenges investors face and has developed a platform that directly addresses those needs.
        </Typography>
      </Box>

      {/* Closing Statement */}
      <Box sx={{  marginTop: '60px' }}>  {/* Added textAlign: 'center' to centralize text */}
        <Typography 
          variant="body1" 
          sx={{ color: theme.palette.primary.main, fontSize: '16px', lineHeight: 1.75, fontWeight: 600 }}
        >
          Join us on our journey to revolutionize software investment research and experience a platform that's built for impact, precision, and growth.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutUs;

