import React, { useState } from 'react';
import { Checkbox, Box, Typography, MenuItem, Select, ListItemText, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '@mui/material/styles';

const FilterTableFields = ({ label,selectedColumns, handleColumnsChange, allColDefs }) => {
  const theme = useTheme();
  const [expandedGroups, setExpandedGroups] = useState({});
  const [localSelectedColumns, setLocalSelectedColumns] = useState(selectedColumns);

  // Group columns by their 'group' property
  const groupedColDefs = allColDefs.reduce((acc, colDef) => {
    const group = colDef.group || 'Ungrouped'; // Default group if no group exists
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(colDef);
    return acc;
  }, {});

  const handleExpandToggle = (group) => {
    setExpandedGroups((prev) => ({ ...prev, [group]: !prev[group] }));
  };
  
  const handleCheckboxChange = (colDef) => (e) => {
    const newValue = e.target.checked;
    const updatedLocalSelectedColumns = newValue
      ? [...localSelectedColumns, colDef.field]
      : localSelectedColumns.filter((field) => field !== colDef.field);

    setLocalSelectedColumns(updatedLocalSelectedColumns);
    handleColumnsChange({ target: { value: updatedLocalSelectedColumns } });
  };

  const handleMenuItemClick = (colDef) => {
    const isChecked = localSelectedColumns.includes(colDef.field);
    handleCheckboxChange(colDef)({ target: { checked: !isChecked } });
  };

  const handleGroupCheckboxChange = (group) => () => {
    const allFields = groupedColDefs[group].map(colDef => colDef.field);
    const allSelected = allFields.every(field => localSelectedColumns.includes(field));

    if (allSelected) {
      // If all items are selected, uncheck all items in this group, but keep "Company Name" checked
      const updatedColumns = localSelectedColumns.filter(field => !allFields.includes(field) || field === 'Company Name');
      setLocalSelectedColumns(updatedColumns);
      handleColumnsChange({ target: { value: updatedColumns } });
    } else {
      // Check all items in this group if any are unchecked
      const updatedColumns = [...new Set([...localSelectedColumns, ...allFields])];
      setLocalSelectedColumns(updatedColumns);
      handleColumnsChange({ target: { value: updatedColumns } });
    }
  };

  return (
    <Box
      size="small"
      sx={{
        
        height: '30px',
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        minWidth: '200px',
        marginTop: '2px',
        maxWidth: '100%',
        flex: 1,
      }}
    >
      <Select
        size="small"
        sx={{ backgroundColor: theme.tableHeader.highlight,width: '100%' }}
        multiple
        value={localSelectedColumns}
        onChange={handleColumnsChange}
        renderValue={() => (
          <Typography
            variant="body2"
            sx={{
              
              fontSize: '12px',
              display: 'flex',
              alignContent: 'center',
              paddingTop: '3px',
              color: theme.palette.text.secondary,
            }}
          >
            {label}
          </Typography>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250,
              width: 'auto',
            },
          },
        }}
      >
        {/* Top-level ungrouped items */}
        {groupedColDefs['Ungrouped'] &&
          groupedColDefs['Ungrouped'].map((colDef) => (
            <MenuItem
              key={colDef.field}
              value={colDef.field}
              disabled={colDef.field === 'Company Name'}
              sx={{
                height: '40px',
                justifyContent: 'space-between',
                backgroundColor: localSelectedColumns.includes(colDef.field)
                  ? theme.palette.action.selected
                  : 'transparent',
                opacity: colDef.field === 'Company Name' ? 0.5 : 1,
              }}
              onClick={() => {
                if (colDef.field === 'Company Name') return; // Prevent toggling for "Company Name"
                handleCheckboxChange(colDef)({ target: { checked: !localSelectedColumns.includes(colDef.field) } });
              }}
            >
              <ListItemText
                primary={<Typography sx={{ color: theme.palette.text.secondary }}  variant="body2">{colDef.field}</Typography>}
              />
              <Checkbox
                size="small"
                checked={localSelectedColumns.includes(colDef.field) || colDef.field === 'Company Name'}
                disabled={colDef.field === 'Company Name'}
                sx={{ padding: 0, opacity: colDef.field === 'Company Name' ? 0.5 : 1 }}
              />
            </MenuItem>
          ))}

        {/* Grouped items */}
        {Object.keys(groupedColDefs)
          .filter((group) => group !== 'Ungrouped')
          .map((group) => {
            const allFields = groupedColDefs[group].map(colDef => colDef.field);
            const allSelected = allFields.every(field => localSelectedColumns.includes(field));
            const anySelected = allFields.some(field => localSelectedColumns.includes(field));
            const isIndeterminate = !allSelected && anySelected;

            return [
              <MenuItem
                key={`${group}-header`}
                sx={{
                  height: '40px',
                  justifyContent: 'space-between',
                  backgroundColor: (allSelected || isIndeterminate) ? theme.palette.action.selected : 'transparent',
                }}

                value={groupedColDefs.field}
              >
                <ListItemText
                  primary={<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>{group}</Typography>}
                />
                <IconButton onClick={() => handleExpandToggle(group)} size="small">
                  {expandedGroups[group] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <Checkbox
                  indeterminate={isIndeterminate}
                  size="small"
                  checked={allSelected}
                  onChange={handleGroupCheckboxChange(group)}
                  sx={{ padding: 0 }}
                />
              </MenuItem>,
              expandedGroups[group] &&
              groupedColDefs[group].map((colDef) => (
                <MenuItem
                  key={colDef.field}
                  disabled={colDef.field === 'Company Name'}
                  sx={{
                    height: '26px',
                    pl: 4,
                    justifyContent: 'space-between',
                    backgroundColor: localSelectedColumns.includes(colDef.field)
                      ? theme.palette.action.selected
                      : 'transparent',
                  }}
                  value={colDef.field}
                  onClick={() => handleMenuItemClick(colDef)}
                >
                  <ListItemText
                    primary={<Typography sx={{ color: theme.palette.text.secondary }} variant="body2">{colDef.field}</Typography>}
                  />
                  <Checkbox
                    size="small"
                    checked={localSelectedColumns.includes(colDef.field) || colDef.field === 'Company Name'}
                    disabled={colDef.field === 'Company Name'}
                    sx={{ padding: 0, opacity: colDef.field === 'Company Name' ? 0.5 : 1 }}
                  />
                </MenuItem>
              ))
            ];
          })}
      </Select>
    </Box>
  );
};

export default FilterTableFields;