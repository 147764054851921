import React from "react";

import data from '../data/charts/segmentation.json';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {FaDatabase, FaIndustry, FaDesktop, FaBuilding} from 'react-icons/fa';
import '../index.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useMediaQuery,ToggleButton,ToggleButtonGroup,Collapse,IconButton,Box } from "@mui/material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MyResponsiveTreeMap from '../components/ChartSegment'
import { useTheme } from '@mui/material/styles';
import { Tooltip, Typography } from '@mui/material';
import FilterCategoryFlat from "../components/FilterCategoryFlat";



  function filterTreeByName(tree, nameToRemove) {
    // Base case: If the current node's name matches the name to remove, return null to filter it out.
    if (tree.name === nameToRemove) {
      return null;
    }
  
    // Recursively filter children and create a new children array without the removed nodes.
    if (tree.children && tree.children.length > 0) {
      const newChildren = [];
      for (const child of tree.children) {
        const filteredChild = filterTreeByName(child, nameToRemove);
        if (filteredChild !== null) {
          newChildren.push(filteredChild);
        }
      }
      tree.children = newChildren;
    }
  
    // Return the updated tree.
    return tree;
  }
  
function Segmentation({open, handleToggleCollapse }){
  
  const theme = useTheme();
  const location = useLocation(); // If using React Router


  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const tableWidth='1800px'
  
  const isTablet = useMediaQuery('(max-width:1080px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
    const items = [
        { id: 1, label: 'Application Software', icon: <FaDesktop /> },
        { id: 2, label: 'Infrastructure Software', icon: <FaDatabase /> },
        { id: 3, label: 'Vertical Software', icon: <FaIndustry /> },
        { id: 4, label: 'Diversified Software', icon: <FaBuilding /> }
        // Add more items as needed
    ];

    const [checked, setChecked] = React.useState([1,2,3]);

    const handleToggle = (event) => {
      const { value: selectedItems } = event.target;
      
      setChecked(prevCheckedCategory => {
          let newState = [...selectedItems];
    
          return newState;
      });
    }

    const [alignment, setAlignment] = React.useState('mktcap');

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };

    let filteredData = JSON.parse(JSON.stringify(data))
    if (checked.indexOf(1) === -1) {
        filteredData = filterTreeByName(filteredData, "Application");
    }
    if(checked.indexOf(2) === -1){
        filteredData = filterTreeByName(filteredData, "Infrastructure");
    }
    if(checked.indexOf(3) === -1){
        filteredData = filterTreeByName(filteredData, "Vertical");
    }
    if(checked.indexOf(4) === -1){
        filteredData = filterTreeByName(filteredData, "Diversified");
        }


    return(
        <>
    <Box sx={{ height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
    <Box
      sx={{
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
                <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', width:'930px',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              maxWidth: '680px',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile?'250px':'65px',
                width: isMobile?'80%':'65px',
                fontSize: '12px',
                textAlign: isMobile?'left':'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Dashboard Filters
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '2%', marginRight: '1%', flexShrink: 1 }}>
              <FilterCategoryFlat items={items} checked={checked} handleToggle={handleToggle}/>

              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '2%', marginRight: '1%', flexShrink: 1 }}>

                <Box
                size="small"
                sx={{
                  
                  height: '30px',
                  width: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: '200px',
                  marginTop: '2px',
                  maxWidth: '100%',
                  flex: 1,
                }}
              >
          <Box sx={{ height:'30px',backgroundColor:theme.tableHeader.highlight,width:'151px',display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                border: theme.palette.mode==='light'?`1px solid ${theme.palette.grey[400]}`:`1px solid ${theme.palette.grey[700]}`, borderRadius: '4px' }}>
          <Box sx={{ maxWidth: '50px',  textAlign: 'center',  paddingBottom: '0px' }}><Typography variant="body2" sx={{paddingLeft:'8px', color:theme.palette.text.secondary,fontSize: '9px' }}> Aggregate By</Typography></Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1 }}>
              <Box sx={{ borderLeft: `0px solid ${theme.palette.divider}`, marginLeft: '0px', marginRight: '0px' }}>
              <ToggleButtonGroup
                sx={{color:theme.palette.text.secondary,backgroundColor:theme.tableHeader.highlight,height:'28px',paddingLeft:'8px',
                  '& .Mui-selected': {
                    color: theme.toggleButton.selectedTextColor,
                    backgroundColor: theme.toggleButton.selectedBackgroundColor,
                  },
                }}
                value={alignment}
                exclusive
                onChange={handleChange}
                size="small"
                aria-label="Time Period Toggle"
              >
                <ToggleButton style={{ textTransform: 'none' }} value="mktcap" sx={{paddingBottom:'12px',minWidth:'40x'}}>
                  <Tooltip title="Market Cap">
                    <IconButton color={alignment === 'mktcap' ? 'primary' : theme.palette.text.secondary}  sx={{ color: alignment === 'mktcap' ? 'primary' : theme.palette.text.secondary, py: 0, px: 0 }}>
                      <Box>
                        <AccountBalanceIcon sx={{fontSize:'16px'}} />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '8px' }}>Mkt Cap</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton style={{ textTransform: 'none' }} value="revenue" sx={{paddingBottom:'12px',minWidth:'40x'}}>
                  <Tooltip title="TTM Revenue">
                    <IconButton color={alignment === 'revenue' ? 'primary' : theme.palette.text.secondary}  sx={{ color: alignment === 'revenue' ? 'primary' : theme.palette.text.secondary,py: 0, px: 0 }}>
                      <Box>
                        <AttachMoneyIcon sx={{fontSize:'16px'}} />
                        <Typography variant="body2" align="center" sx={{ mt: -1, fontWeight: 'bold', fontSize: '8px' }}>Revenue</Typography>
                      </Box>
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
             </Box>
             </Box>
          </Box>
          </Box>
              </Box>
            </Box>
          </Box>
        </Box>


        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
    </Box>
    </Collapse>
    </Box>

    
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box sx={{ 
      flex: 1, display: 'flex', 
      justifyContent:'space-between',
      paddingBottom: '6px', 
      width: tableWidth, 
      maxWidth: "98%", 
      color: theme.palette.primary.main, 
      fontSize: '20px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >
    {('Market Segmentation')}

  </Box>
  <Box sx={{ display: 'flex', height: '100%', maxWidth:'95%',width: tableWidth }}>
  <div
    style={{
      position: 'relative',
      flex: 1, // This allows it to grow and fill the container
    }}
  >
    <div style={{
      position: 'absolute', width: '100%', height: '98%',
    }}>
          <MyResponsiveTreeMap data={filteredData} value={alignment} theme={theme} />
          <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 25, // Adjust the distance from the bottom
                right: 28, // Adjust the distance from the right
                width: '110px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.4, // Set the desired opacity for the watermark
              }}
            />
        </div>
            </div>
            </Box>
    </Box>

        </Box>
        </>
    
    )
}
export default Segmentation