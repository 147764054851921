import React from 'react';
import '../index.css';
import { Box, Typography, Button, Paper } from '@mui/material';
import { useTheme } from '@emotion/react';
import LoginIcon from '@mui/icons-material/Login';

import { useAuth0 } from "@auth0/auth0-react";

const LoginNotice = () => {
  const theme = useTheme();
  const { loginWithRedirect} = useAuth0();
  const handleSignupClick = () => {
    // Save the current page URL in localStorage before redirecting for signup
    const returnTo = window.location.href;
    localStorage.setItem('redirectTo', returnTo); // Store redirect URL
    loginWithRedirect({authorizationParams: {
      screen_hint: "signup",
    }});
  };
  const handleLoginClick = () => {
    // Save the current page URL in localStorage before redirecting for login
    const returnTo = window.location.href;
    localStorage.setItem('redirectTo', returnTo); // Store redirect URL
    loginWithRedirect(); // Trigger Auth0 login
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '67vh',
        maxWidth:'100%',
        marginTop:'5%',
        marginLeft:'5%',
        marginRight:'5%',
        marginBottom:'5%',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: theme.spacing(8),
          textAlign: 'center',
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <LoginIcon
          sx={{
            fontSize: 60,
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(2),
          }}
        />
        <Typography variant="h4" gutterBottom>
          User account required
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please sign in or create a free account today!
        </Typography>
        <Box sx={{ margin: theme.spacing(2) }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: theme.spacing(2) }}
            onClick={handleLoginClick}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSignupClick}
          >
            Create Account
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginNotice;