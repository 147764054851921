import React from "react";
import '../index.css';
import { useState,useEffect } from "react";
import { useLocation } from 'react-router-dom';
import data from '../data/charts/csp.json';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useMediaQuery,Collapse,Box, IconButton, } from '@mui/material';
import ReactGA from 'react-ga4';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import gcpLogo from '../data/images/GCP.png'
import azureLogo from '../data/images/Azure.png'
import awsLogo from '../data/images/AWS.png'
import ociLogo from '../data/images/OCI.png'
import { useTheme } from '@mui/material/styles';
import MyResponsiveLine from "../components/ChartCSP";
import FilterCategoryCSP from "../components/FilterCategoryCSP";
import FilterSlider from "../components/FilterSlider";




function CloudService({open,handleToggleCollapse}){
  useEffect(() => {
    window.scrollTo(0, 0);


  }, []); 
  const items = [
    { id: 1, filter:'AWS',label: 'AWS', icon: awsLogo },
    { id: 2, filter:'Azure',label: 'Azure', icon: azureLogo },
    { id: 3, filter:'Google Cloud',label: 'Google Cloud', icon: gcpLogo },
    { id: 4, filter:'Oracle IaaS',label: 'Oracle IaaS', icon: ociLogo},
    // Add more items as needed
];
  const theme = useTheme();
  const location = useLocation(); // If using React Router
  const calculateMaxSliderValue = () => {

      return 40; // Or any other default value you prefer
    };
  
  
  const maxSliderValue = calculateMaxSliderValue();
  const tableWidth = '1600px'
  
  const isTablet = useMediaQuery('(max-width:1080px)'); // Adjust breakpoint as needed
  const isMobile  = useMediaQuery('(max-width:480px)'); // Adjust breakpoint as needed
  // Function to handle button clicks and update the selected button state
  
  const initialSliderValue = isMobile? 6: isTablet?16:24;
  const [sliderValue, setValue] = useState(initialSliderValue);
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);

  };
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlur = () => {
    if (sliderValue < 2 || isNaN(sliderValue)) {
      setValue(2);
    } else if (sliderValue > maxSliderValue) {
      setValue(maxSliderValue);
    }
  };
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // If you want to scroll to the top every time the component updates, you can include an empty dependency array []
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts
      

     const [checked, setChecked] = React.useState([1,2,3,4]);
     const handleToggle = (event) => {
      const value = event.target.value;
      setChecked(value);
    };
     const filteredData = data
      .filter(item => {
        const selectedItem = items.find(i => i.filter === item.id);
        return selectedItem && checked.includes(selectedItem.id);
      })
      .map(provider => ({
        id: provider.id,
        data: provider.data.slice(-sliderValue) // Use slice to get the last 'sliderValue' number of quarters
      }));

    return(
        
      <>
      <Box sx={{ height: '100%', marginBottom: '0px', display: 'flex', flexDirection: 'column' }}>
      <Box
      sx={{
        backgroundColor: theme.background.backgroundColor,
        marginBottom: '12px', // Ensure no gap
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        boxShadow: theme.palette.mode === 'light'
      ? '0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)' // Light mode
      : '0px 1px 1px -1px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.24), 0px 3px 3px 0px rgba(0,0,0,0.4)', // Dark mode
      }}
      elevation={0} // No default elevation, custom shadow
    >
      <Collapse in={open} sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', marginTop:'0px',paddingLeft: '0%', marginLeft: '0px' }}>
                <Box elevation={0} sx={{ paddingBottom: '4px', maxWidth: '100%', paddingTop: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Box sx={{ display: 'flex', width:'930px',flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

          
          <Box
            elevation={theme.palette.mode == 'dark' ? 0 : 0}
            sx={{
              border: '0px solid lightgrey',
              width: '100%',
              maxWidth: '680px',
              marginLeft: '6px',
              paddingTop: '4px',
              marginTop: '0px',
              paddingLeft: '6px',
              paddingRight: '8px',
              paddingBottom: '0px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={{
                maxWidth: isMobile?'200px':'65px',
                fontSize: '12px',
                textAlign: 'center',
                paddingBottom: '2px',
                paddingTop: '4px',
                color: theme.palette.text.secondary,
                flexShrink: 0,
              }}
            >
              Dashboard Filters
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, flexWrap: 'wrap' }}>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '2%', marginRight: '1%', flexShrink: 1 }}>
              <FilterCategoryCSP items={items} checked={checked} handleToggle={handleToggle}/>

              </Box>
              <Box sx={{marginTop:'2px', marginBottom: '2px', marginLeft: '2%', marginRight: '1%', flexShrink: 1 }}>

                <FilterSlider sliderValue={sliderValue} maxSliderValue={maxSliderValue} handleSliderChange={handleSliderChange} handleInputChange={handleInputChange} handleBlur={handleBlur}/>
              </Box>
            </Box>
          </Box>
        </Box>


        <IconButton
            sx={{
              p: 0.1,
              fontSize: 15,
              marginTop: '2px',
              marginRight: '2px',


            }}
            onClick={handleToggleCollapse}
            aria-label="toggle-collapse"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
    </Box>
    </Collapse>
    </Box>

      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box sx={{ 
      flex: 1, display: 'flex', 
      justifyContent:'space-between',
      paddingBottom: '6px', 
      width: tableWidth, 
      maxWidth: "98%", 
      color: theme.palette.primary.main, 
      fontSize: '20px', 
      fontWeight: '300 ' , // Use !important to enforce the style
      textAlign: 'left', // Ensure text is left-aligned
      paddingLeft: '.5%',
  }}
  style={{ fontWeight: '300' }} // Inline style for testing
  >
    {!isMobile&&('')}
    {'Cloud Infrastructure Growth'} 

  </Box>
  <Box sx={{ display: 'flex', height: '100%', maxWidth:'95%',width: tableWidth }}>
  <div
    style={{
      position: 'relative',
      flex: 1, // This allows it to grow and fill the container
    }}
  >
    <div style={{
      position: 'absolute', width: '100%', height: '98%',
    }}>
        <MyResponsiveLine data={filteredData} theme={theme} />
        <img
              src={require('../data/images/logo.png')}
              alt="Website Logo"
              style={{
                position: 'absolute',
                bottom: 98, // Adjust the distance from the bottom
                left:  65, // Adjust the distance from the right
                width: '150px', // Set the desired width for the postage stamp
                height: 'auto', // Maintain aspect ratio
                opacity: 0.2, // Set the desired opacity for the watermark
              }}
            />
            </div>
            </div>
            </Box>
    </Box>
      </Box>
      </>
      
    )
}
export default CloudService;